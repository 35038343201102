import React, { useState, useEffect,useContext } from 'react';
import styled,{keyframes} from 'styled-components';
import { Loader } from 'semantic-ui-react';
import { CopilotContext } from '../CopilotContext';


function extractAndParseJSON(llmResponse) {
    let cleanedResponse = llmResponse.trim();
    let finalResult;
    let lastError = null;
    
    // Regex to match JSON inside markdown-style code blocks
    const jsonRegex = /```json\s*([\s\S]*?)\s*```/;
    let match = cleanedResponse.match(jsonRegex);

    if (match) {
        cleanedResponse = match[1]; // Extract only the JSON part
    }

    // Try parsing JSON normally
    try {
        finalResult = JSON.parse(cleanedResponse);
        return { success: true, data: finalResult };
    } catch (parseErr) {
        lastError = parseErr;
        console.error("JSON parse failed (direct):", parseErr.message);
    }

    // Fallback: Try extracting JSON manually from within the response
    try {
        let jsonMatch = cleanedResponse.match(/\{[\s\S]*\}/);
        if (jsonMatch) {
            finalResult = JSON.parse(jsonMatch[0]); // Try parsing the extracted JSON object
            return { success: true, data: finalResult };
        }
    } catch (parseErr) {
        lastError = parseErr;
        console.error("JSON parse failed (fallback extraction):", parseErr.message);
    }

    return { success: false, error: lastError ? lastError.message : "Invalid JSON format" };
}

const AICard = ({ start, alreadyRun,prompt,onDone }) => {
  const [finished, setFinished] = useState(false);

  const { callOpenAIJSON, setMessages } = useContext(CopilotContext);

  useEffect(() => {
    // If we have not already run, then trigger a new generation
    if (!alreadyRun) {
      const t = setTimeout(() => {
        processSteps();
        
      }, 3000);

      return () => clearTimeout(t);
    } else {
      // If already run, skip new generation and just appear "finished"
      setFinished(true);
    }
  }, [alreadyRun]);


  async function processSteps() {
    try {
      const response = await generateClusterThemes();
      setFinished(true);
  
      // We'll capture the updated array in a local variable
      let updatedMessages = [];
      setMessages((prev) => {
        updatedMessages = [
          ...prev,
          { role: 'assistant', content: response }
        ];
        return updatedMessages;
      });
  
      // Now that we have updatedMessages, pass it to onDone
      onDone?.(updatedMessages);
    } catch (error) {
      console.error('Error in processing steps:', error);
      setFinished(true);
    }
  }


  async function generateClusterThemes() {
    let attempts = 0;
    const maxAttempts = 3;
    let lastError = null;
    let finalResult = null;

    while (attempts < maxAttempts) {
      attempts++;

      const promptIntro = attempts === 1
        ? ''
        : 'Your previous response was not valid JSON. Please correct it.\n';

        const prompt2 = `
        ${promptIntro}
        
        Based your response on the following data: ${prompt}

        return valid json only. 

        Please generate a 1 sentence description for each factor identified. Here are some example sentences:

        - This shows that feeling safe, understood, and able to express ideas without fear significantly boosts well-being.
        - Being able to adjust to changes positively impacts how we feel.
        - Understanding what's expected and having clear goals help people feel better in general.
  
        Use the following JSON format exactly (no extra keys outside this array):
        {descriptions:[Description1, Description2, Description3]}`
      ;

      let llmResponse;
      try {
        llmResponse = await callOpenAIJSON(prompt2);
      } catch (err) {
        lastError = err;
        console.error(`LLM call failed on attempt #${attempts}:`, err);
        continue;
      }

      finalResult = extractAndParseJSON(llmResponse);
    
    }
    console.log('Final result:', finalResult);

    if (finalResult.success) {
      return finalResult.data;
    }

    return {}

  }

  return (
    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%',marginTop:20}}>
      <Card>
        {!finished ? (
          <>
            <Title>Building model & running analysis</Title>
            <Loader active inline size="mini" style={{marginLeft:20}} />
          </>
        ) : (
          <Title>Completed Analysis</Title>
        )}
      </Card>
    </div>
  );
};

export default AICard;




const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Card = styled.div`
    padding: 20px;
    border-radius: 10px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
        opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;

 opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`