// index.js (Workflow Component)
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import Home from './Home';
import RiskHome from './Workflows/RiskHome';
import ROI from './Workflows/ROIHome';
import Thematic from "./Workflows/Thematic";
import Change from './Workflows/Change';

function HistorySidebar({ history, onSelectSession, onGoHome, isOpen }) {
    console.log(history)
    const getTitle = (item) => {
        if(item.type == 'risk' && item?.selectedFactors?.type == 'Culture'){
            return `${item.selectedFactors.type} - ${item.type}`
        }
        if(item.selectedFactors?.type == 'Overview'){
            return 'Overview'
        }else if(item.selectedFactors?.title){
            return `${item.selectedFactors?.title} - ${item.type}`
        }
        else if (item.type == 'change'){
            return `Change Analysis`
        }
        else{
            return `${item.selectedFactors?.name} - ${item.type}`
        }
    }

  return (
    <SidebarContainer isOpen={isOpen}>
      <SidebarHeader>
        <SidebarTitle>Workflow History</SidebarTitle>
        <IconGroup>
          <ActionIcon 
            name="plus" 
            onClick={onGoHome}
            title="Start new workflow"
          />
        </IconGroup>
      </SidebarHeader>
      <HistoryList>
        {history.length === 0 ? (
          <EmptyState>No previous sessions found</EmptyState>
        ) : (
          history.map((item) => (
            <SessionItem key={item.id} onClick={() => onSelectSession(item)}>
              <SessionDetails>
                <SessionType>{getTitle(item)}</SessionType>
                <Filters>
                    {item?.filters?.map((filter, idx) => (
                        <Filter key={idx}>{filter.name}</Filter>
                    ))}
                </Filters>
                <SessionDate>{new Date(item.id).toLocaleDateString()}</SessionDate>
              </SessionDetails>
            </SessionItem>
          ))
        )}
      </HistoryList>
    </SidebarContainer>
  );
}

const Workflow = () => {
  const [workflow, setWorkflow] = useState(null);
  const [history, setHistory] = useState([]);
  const [currentSession, setCurrentSession] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleSaveSession = (sessionData) => {
    const newSession = { ...sessionData, id: Date.now() };
    setHistory((prev) => [newSession, ...prev]);
  };

  const handleSelectSession = (session) => {
    setCurrentSession(session);
    setWorkflow(session.type);
    setIsSidebarOpen(false);
  };

  const handleGoHome = () => {
    setWorkflow(null);
    setCurrentSession(null);
    setIsSidebarOpen(true);
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  let mainArea;
  if (!workflow) {
    mainArea = (
      <Home 
        setWorkflow={(type) => {
          setWorkflow(type);
          setIsSidebarOpen(false);
        }} 
      />
    );
  } else if (workflow === 'risk') {
    mainArea = (
      <RiskHome
        goHome={() => {
          setWorkflow(null);
          setCurrentSession(null);
        }}
        initialSession={currentSession}
        onSaveSession={handleSaveSession}
      />
    );
  } else if (workflow === 'roi') {
    mainArea = (
      <ROI
        goHome={() => {
          setWorkflow(null);
          setCurrentSession(null);
        }}
        initialSession={currentSession}
        onSaveSession={handleSaveSession}
      />
    );
  } else if (workflow === 'thematic') {
    mainArea = (
      <Thematic
        goHome={() => {
          setWorkflow(null);
          setCurrentSession(null);
        }}
        initialSession={currentSession}
        onSaveSession={handleSaveSession}
      />
    );
  } else if (workflow === 'change') {
    mainArea = (
      <Change
        goHome={() => {
          setWorkflow(null);
          setCurrentSession(null);
        }}
        initialSession={currentSession}
        onSaveSession={handleSaveSession}
      />
    );
  }

  return (
    <AppContainer>
      <HistorySidebar
        history={history}
        onSelectSession={handleSelectSession}
        onGoHome={handleGoHome}
        isOpen={isSidebarOpen}
      />
      
      <SidebarToggleButton 
        onClick={toggleSidebar} 
        isOpen={isSidebarOpen}
        aria-label={isSidebarOpen ? "Collapse sidebar" : "Expand sidebar"}
      >
        <ToggleIcon name={isSidebarOpen ? 'chevron left' : 'chevron right'} />
      </SidebarToggleButton>

      <MainWorkspace isOpen={isSidebarOpen}>
        {mainArea}
      </MainWorkspace>
    </AppContainer>
  );
};

export default Workflow;

// Styled Components
const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f5f7f9;
  position: relative;
`;

const SidebarContainer = styled.div`
  width: 280px;
  height: 100vh;
  background: #ffffff;
  box-shadow: 2px 0 12px rgba(0,0,0,0.08);
  position: fixed;
  top: 0;
  left: 200px;
  transform: translateX(${props => props.isOpen ? '0' : '-100%'});
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  font-family: 'Raleway', sans-serif;
`;

const SidebarHeader = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e8ebee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SidebarTitle = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  color: #2c3e50;
  font-weight: 600;
`;

const IconGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionIcon = styled(Icon)`
  cursor: pointer;
  color: #6c757d;
  transition: color 0.2s ease;

  &:hover {
    color: #2c3e50;
  }
`;

const HistoryList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`;

const SessionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 8px;
  background: ${props => props.isActive ? '#f8f9fa' : 'transparent'};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f8f9fa;
    transform: translateX(4px);
  }
`;

const HistoryIcon = styled(Icon)`
  color: #7f8c8d;
  margin-right: 12px;
`;

const SessionDetails = styled.div`
  flex: 1;
`;

const SessionType = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2A3039;
`;

const SessionDate = styled.div`
  font-size: 0.8rem;
  color: #7f8c8d;
  margin-top: 2px;
`;

const SessionTime = styled.div`
  font-size: 0.75rem;
  color: #95a5a6;
`;

const EmptyState = styled.div`
  padding: 24px;
  text-align: center;
  color: #95a5a6;
  font-size: 0.9rem;
`;

const SidebarToggleButton = styled.button`
  position: fixed;
  left: ${props => props.isOpen ? '480px' : '200px'};
  top: 24px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: none;
  border-radius: 0 6px 6px 0;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateX(${props => props.isOpen ? '0' : '0'});

  &:hover {
    background: #f8f9fa;
    transform: translateX(${props => props.isOpen ? '4px' : '4px'});
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(44, 62, 80, 0.1);
  }
`;

const ToggleIcon = styled(Icon)`
  color: #2c3e50;
  margin: 0 !important;
`;

const MainWorkspace = styled.main`
  flex: 1;
  margin-left: ${props => props.isOpen ? '280px' : '0'};
  transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  min-height: 100vh;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 12px 0 0 12px;
  box-shadow: -2px 0 12px rgba(0,0,0,0.04);
`;

const Filters = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
`

const Filter = styled.div`
    background: #f8f9fa;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #7f8c8d;
`
