// App.jsx
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Sidebar from "./SideBar";
import FactorDetail from "./FactorDetail";
import SummaryChart from "./SummaryChart";
import { formatElements } from "../../Functions/parseText";

// Keyframes for a simple fade in/out
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const AppContainer = styled.div`
  display: flex;
  font-family: "Raleway", sans-serif;
  position: relative; /* To allow the overlay to cover the content */
`;

const ContentArea = styled.div`
  padding: 20px 30px;
  animation: ${fadeIn} 0.5s ease-in-out;
  width: calc(100% - 200px);
`;

const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #2a3039;
  margin-bottom: 30px;
`;

const Placeholder = styled.p`
  font-style: italic;
  color: #888;
`;

const LoadingOverlay = styled.div`
  display: flex;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: #2d70e2;
`;

function App({ change_data, done }) {
  // Merge incoming change_data over time.
  const [data, setData] = useState({});
  const [selectedFactor, setSelectedFactor] = useState(null);
  // Start on summary until the first factor is available.
  const [view, setView] = useState("summary");

  const {
    totalCultureFactors = 0,
    processedCultureCount = 0,
    totalOutcomeFactors = 0,
    processedOutcomeCount = 0
  } = data.progress || {};

  // Merge new incoming data.
  useEffect(() => {
    if (change_data) {
      setData((prevData) => ({ ...prevData, ...change_data }));
    }
  }, [change_data]);

  // Once any culture factor appears, default to the first factor.
  useEffect(() => {
    const cultureFactors = data?.culture_factors || [];
    // Only auto-select if no factor is selected and the current view is not "summary".
    if (cultureFactors.length > 0 && !selectedFactor && view !== "summary") {
      // Sort factors based on absolute change_percentage and choose the first one.
      const sortedCultureFactors = [...cultureFactors].sort(
        (a, b) => Math.abs(b.change_percentage) - Math.abs(a.change_percentage)
      );
      setSelectedFactor(sortedCultureFactors[0]);
      setView("detail");
    }
  }, [data, selectedFactor, view]);

  // Prepare factors (or empty arrays if not yet present)
  const cultureFactors = data?.culture_factors || [];
  const outcomeFactors = data?.outcome_factors || [];

  // Sorting the arrays (if available)
  const sortedCultureFactors = [...cultureFactors].sort(
    (a, b) => Math.abs(b.change_percentage) - Math.abs(a.change_percentage)
  );
  const sortedOutcomeFactors = [...outcomeFactors].sort(
    (a, b) => Math.abs(b.change_percentage) - Math.abs(a.change_percentage)
  );

  const handleSelectFactor = (factor) => {
    setSelectedFactor(factor);
    setView("detail");
  };

  const handleViewChange = (newView) => {
    setView(newView);
    if (newView === "summary") {
      // Optionally clear selectedFactor when returning to summary.
      setSelectedFactor(null);
    }
  };

  const isStillLoading =
    !done ||
    processedCultureCount < totalCultureFactors ||
    processedOutcomeCount < totalOutcomeFactors;

  return (
    <AppContainer>
      
      {/* Pass loading and progress info to Sidebar */}
      <Sidebar
        onSelectFactor={handleSelectFactor}
        onViewChange={handleViewChange}
        selected={selectedFactor}
        cultureFactors={sortedCultureFactors}
        outcomeFactors={sortedOutcomeFactors}
        loading={isStillLoading}
        progress={data.progress}
      />
      <ContentArea>
        {view === "summary" && (
          <>
            <Title>Survey Summary</Title>
            <SubTitle>Change Overview</SubTitle>
            {cultureFactors.length > 0 ? (
              <SummaryChart
                cultureFactors={cultureFactors}
                outcomeFactors={outcomeFactors}
              />
            ) : (
              <Placeholder>Generating chart data...</Placeholder>
            )}
            <SubTitle>Culture Factors</SubTitle>
            {data.tldr_culture ? (
              formatElements(data.tldr_culture)
            ) : (
              <Placeholder>Generating culture factors summary...</Placeholder>
            )}
            {outcomeFactors.length > 0 && (
              <>
                <SubTitle>Outcome Factors</SubTitle>
                {data.tldr_outcome ? (
                  formatElements(data.tldr_outcome)
                ) : (
                  <Placeholder>Generating outcome factors summary...</Placeholder>
                )}
              </>
            )}
          </>
        )}
        {view === "detail" && selectedFactor && (
          <FactorDetail factor={selectedFactor} />
        )}
      </ContentArea>
    </AppContainer>
  );
}

export default App;
