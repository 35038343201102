import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import html2canvas from 'html2canvas';
import {Icon} from 'semantic-ui-react';
import IMG from "assets/images/stock/office.png";
import Top from 'ProtoType/Iceburg/Top';
import GreenBolt from 'assets/images/UI/green_bolt.svg';
import GreenImage from 'assets/images/stock/undraw_projections_re_ulc6.png';
import YelloStar from 'assets/images/UI/yellow_star.svg';
import YellowImage from 'assets/images/stock/undraw_articles_wbpb.png';
import BlueRocket from 'assets/images/UI/blue_rocket.svg';
import BlueImage from 'assets/images/stock/undraw_financial_data_re_p0fl.png';
import PurpleFlag from 'assets/images/UI/purple_flag.svg';
import PurpleImage from 'assets/images/stock/undraw_electricity_k2ft.png';

// Color definitions for each report type
const colors = {
  'Well-being': { c1: "#1D9A7D", c2: "#EAFBF7", c3: "#27CDA7", c4: "#D4F5ED", icon: GreenBolt, img: GreenImage },
  'Retention': { c1: "#FEA711", c2: "#FFFAF1", c3: "#FEA711", c4: "#FFEDCF", icon: YelloStar, img: YellowImage },
  'Engagement': { c1: "#2D70E2", c2: "#F8FAFF", c3: "#2D70E2", c4: "#EBF1FD", icon: BlueRocket, img: BlueImage },
  'Innovation': { c1: "#D05BFF", c2: "#FAEEFF", c3: "#D05BFF", c4: "#F7E2FF", icon: PurpleFlag, img: PurpleImage },
};

// ─── Styled Components ────────────────────────────────────────────────

const ROIReport = ({ data, type, content }) => {
    const reportRef = useRef(null);
    const reportType = type.name;
  
    // Function to capture and download the report image
    const captureReportImage = async () => {
      if (!reportRef.current) return;
  
      const canvas = await html2canvas(reportRef.current, {
        scale: 2,
        backgroundColor: null,
      });
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${reportType}-ROIReport.png`;
      link.click();
    };
  
    const getConditionalText = (d, i) => {
      if (i === 0) {
        return (
          <FactorText>
            This is the biggest influencer. If we increase {d.name} by <strong>10%</strong>, {reportType} could potentially improve by about <strong>{d.impact.toFixed(1)}%</strong>.
            {content?.descriptions?.[i]}
          </FactorText>
        );
      } else if (i === 1) {
        return (
          <FactorText>
            Next is {d.name}. A <strong>10%</strong> increase in {d.name.toLowerCase()} could lead to a <strong>{d.impact.toFixed(1)}%</strong> improvement in {reportType}. Being able to adjust to changes positively impacts how we feel.
            {content?.descriptions?.[i]}
          </FactorText>
        );
      } else if (i === 2) {
        return (
          <FactorText>
            Lastly, {d.name} plays a role. Increasing {d.name.toLowerCase()} by <strong>10%</strong> may enhance {reportType} by <strong>{d.impact.toFixed(1)}%</strong>. Understanding what's expected and having clear goals help people feel better in general.
            {content?.descriptions?.[i]}
          </FactorText>
        );
      }
    };
  
    return (
      <ReportWrapper ref={reportRef}>
        {/* Download Icon appears on hover */}
        <DownloadIconWrapper className="download-icon" onClick={captureReportImage}>
          <Icon name="download" size="large" />
        </DownloadIconWrapper>
  
        <Container>
          <TopTitle>
            Summary of <Colored color={colors[reportType]}>{type.name}</Colored> Outcome Analysis
          </TopTitle>
          <Text>
            In our recent analysis of {reportType.toLowerCase()} outcomes, we used a statistical method called multiple regression to understand what factors influence {reportType.toLowerCase()}.
          </Text>
  
          <Text>Here's what we found:</Text>
  
          <Content color={colors[reportType].c2}>
            <IconContainer color={colors[reportType].c4}>
              <IconImg src={colors[reportType].icon} alt={`${type.name} icon`} />
            </IconContainer>
            <Influencers color={colors[reportType].c1}>Key Influencers</Influencers>
            <SmallText>
              The analysis identified three main factors that most influence {reportType.toLowerCase()}:
            </SmallText>
  
            {data.data.map((d, i) => (
              <Factor key={i}>
                <FactorTitle>
                  <Num color={colors[reportType].c3}>{i + 1}</Num>
                  {d.name}
                </FactorTitle>
                {getConditionalText(d, i)}
              </Factor>
            ))}
          </Content>
  
          <Conclusion>
            <TopTitle>Conclusion</TopTitle>
            <ConclusionText>
              In simple terms, this analysis tells us that when people feel safe, can adapt, and have clear directions, their overall well-being tends to be much better. These factors are powerful drivers of how positively people feel at any given time.
            </ConclusionText>
            <ConclusionImage src={colors[reportType].img} alt="Conclusion visual" />
          </Conclusion>
        </Container>
      </ReportWrapper>
    );
  };
  
  export default ROIReport;


const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0);}
`;

const TopTitle = styled.div`
    color:#2A3039;
    font-size:24px;
    letter-spacing:-4%;
    font-weight:500;
    font-family:'Outfit', sans-serif;
    margin-bottom:40px;
`


const Colored = styled.span`
    font-weight:bold;
    color:${props=>props.color.c1};
    background-color:${props=>props.color.c2};
    padding:5px 10px;
    border-radius:5px;
`

const Container = styled.div`
    width:100%;
    margin-bottom:40px;
    opacity: 0;
    animation: ${fadeIn} 0.5s forwards;
    padding: 70px 60px;
    border: 1px solid #E5E5E5;

`

const Influencers = styled.div`
    color:${props=>props.color};
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const SmallText = styled.div`
    color:#2A3039;
    font-size:12px;
    font-weight:500;
    margin-bottom:20px;
`

const Content = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    background-color:${props=>props.color};
    padding:30px;
    border-radius:16px;
`

const Text = styled.div`
    color:#2A3039;
    margin-bottom:20px;

`

const Factor = styled.div`
    font-family:'Raleway', sans-serif;
`

const FactorTitle = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:10px;
    color:#2A3039;
    font-size:16px;
    font-weight:bold;

`

const Num = styled.div`
    background-color:${props=>props.color};
    color:white;
    border-radius:50%;
    width:24px;
    height:24px;
    font-size:14px;
    font-weight:600;
    margin-right:10px;
    display:flex;
    justify-content:center;
    align-items:center;
`

const FactorText = styled.div`
    color:#2A3039;
    font-size:14px;
    font-weight:400;
    margin-bottom:20px;
    padding-left:40px;
    line-height:1.5;
`

const Conclusion = styled.div`
    width:100%;
    margin-top:40px;
    position:relative;
    padding-bottom:100px;

`

const ConclusionText = styled.div`
    color:#2A3039;
    font-size:14px;
    font-weight:400;
    line-height:1.5;
    margin-bottom:20px;
    width:50%;
`

const IconContainer = styled.div`
    width:40px;
    height:40px;
    background-color:${props=>props.color};
    border-radius:8px;
    display:flex;
    justify-content:center;
    align-items:center;  
    margin-bottom:20px;
`

const IconImg = styled.img`
    width:24px;
`

const ConclusionImage = styled.img`
    width:250px;
    position:absolute;
    right:0;
    top:0;

`
// Wrap the entire report in a relative container so the download icon can be positioned
const ReportWrapper = styled.div`
  position: relative;
   background-color: white;

  /* When hovering over the report, show the download icon */
  &:hover .download-icon {
    opacity: 1;
  }
`;

const DownloadIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0; /* hidden by default */
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  z-index: 10;
`;