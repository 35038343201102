// ActionPlanningAgent.js
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { useSelector, shallowEqual } from 'react-redux';

import { ActionPlanContext } from './ActionPlanningContext.js';

function ActionPlanningAgent() {
  const { 
    actionPlanMessages, 
    sendToLLM, 
    addUserMessage,
    currentActionPlan, 
    setCurrentActionPlan
  } = useContext(ActionPlanContext);

  // Example of hooking into Redux for data
  const core_data = useSelector((state) => state.audit?.core_data, shallowEqual);

  // We’ll store the user’s selected data source(s)
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  // The user’s new message to the agent
  const [userInput, setUserInput] = useState('');

  // For demonstration, we might fetch or build the data source dropdown
  const dataSourceOptions = [
    { text: 'Culture data', value: 'culture' },
    { text: 'Engagement data', value: 'engagement' },
    // add more...
  ];

  // Handle user sending a new message
  async function handleSendMessage() {
    if (!userInput.trim()) return;
    addUserMessage(userInput);
    await sendToLLM(userInput);
    setUserInput('');
  }

  // On first load, you might want to send a “system” or greeting message
  useEffect(() => {
    if (actionPlanMessages.length === 0) {
      sendToLLM("Hello, I'd like to create an action plan.").then(() => {
        // could do something afterwards
      });
    }
  }, []);

  // If the LLM eventually returns a final plan, you might parse it out 
  // and set it in `currentActionPlan`.

  // Render each message in a minimal chat style
  return (
    <Container>
      <h2>Culture Action Planning</h2>
      <Dropdown
        placeholder="Select Data Source"
        selection
        clearable
        options={dataSourceOptions}
        value={selectedDataSource}
        onChange={(e, { value }) => setSelectedDataSource(value)}
      />

      <ChatContainer>
        {actionPlanMessages.map((msg, idx) => {
          const isUser = msg.role === 'user';
          return (
            <MessageBubble key={msg.id || idx} isUser={isUser}>
              <strong>{isUser ? "You: " : "Agent: "}</strong>
              {msg.content}
            </MessageBubble>
          );
        })}
      </ChatContainer>

      {/* Once a final plan is returned, you might display it here */}
      {currentActionPlan && (
        <ActionPlanView>
          <h3>Your Action Plan</h3>
          <pre>{JSON.stringify(currentActionPlan, null, 2)}</pre>
        </ActionPlanView>
      )}

      <InputContainer>
        <textarea 
          value={userInput}
          placeholder="Ask for an action plan, or answer agent's questions..."
          onChange={(e) => setUserInput(e.target.value)}
        />
        <button onClick={handleSendMessage}>Send</button>
      </InputContainer>
    </Container>
  );
}

export default ActionPlanningAgent;

// Styled components
const Container = styled.div`
  padding: 20px;
  max-width: 700px;
  margin: 0 auto;
`;

const ChatContainer = styled.div`
  border: 1px solid #ddd;
  height: 300px;
  overflow-y: auto;
  padding: 10px;
  margin-top: 20px;
`;

const MessageBubble = styled.div`
  margin: 8px 0;
  text-align: ${(props) => (props.isUser ? 'right' : 'left')};
`;

const InputContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;

  textarea {
    flex: 1;
    padding: 8px;
    font-size: 14px;
    resize: none;
    height: 50px;
  }
`;

const ActionPlanView = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
`;
