// Sidebar.jsx
import React from "react";
import styled, { keyframes } from "styled-components";
import { useSelector, shallowEqual } from "react-redux";

// Sidebar container styles
const SidebarContainer = styled.div`
  width: 200px;
  min-width: 200px;
  background-color: #ffffff;
  color: #2a3039;
  padding: 5px;
  border-right: 1px solid #d5e2f9;
  overflow-y: auto;
  font-family: "Raleway", sans-serif;
`;

// Header for each section in the sidebar
const SidebarHeader = styled.div`
  color: #2a3039;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

// Keyframes for a fade-in/slide-in animation
const fadeInSlide = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Navigation items with animation and hover effects
const NavItem = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => (props.active ? "#2D70E2" : "#666D79")};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  background-color: ${(props) => (props.active ? "#F8FAFF" : "white")};
  padding: 5px;
  font-family: "Raleway", sans-serif;
  animation: ${fadeInSlide} 0.5s ease-in-out;
  transition: transform 0.2s, color 0.2s;

  &:hover {
    color: #2d70e2;
    transform: scale(1.02);
  }
`;

// For nested items, if needed.
const SubNavItem = styled(NavItem)`
  padding-left: 0px;
`;

// Change percentage styling for factor values.
const ChangePercentage = styled.span`
  font-size: 12px;
  color: ${(props) =>
    props.isPositive && !props.isNeutral ? "#2D70E2" : "#FF0000"};
  margin-left: 5px;
`;

// Spinner animation (rotating circle)
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 12px;
  height: 12px;
  border: 2px solid #ccc;
  border-top: 2px solid #2d70e2;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin-left: 8px;
`;

// Divider between sections
const Divider = styled.hr`
  border: none;
  border-top: 1px solid #d5e2f9;
  margin: 15px 0;
`;

// Filters text at the top of the sidebar.
const Filters = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
`;

// Helper function to compare score changes for sorting.
const getScoreChange = (factor1, factor2) => {
  let factor1Change = factor1.latest_score - factor1.last_score;
  let factor2Change = factor2.latest_score - factor2.last_score;

  if (
    typeof factor1.last_score === "string" ||
    typeof factor2.last_score === "string"
  ) {
    return 0;
  }

  return factor2Change - factor1Change;
};

function Sidebar({
  onSelectFactor,
  onViewChange,
  cultureFactors,
  outcomeFactors,
  selected,
  loading,
  progress, // Optional progress info: { totalCultureFactors, processedCultureCount, totalOutcomeFactors, processedOutcomeCount }
}) {
  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );

  return (
    <SidebarContainer>
      <Filters>
        This report is generated for:{" "}
        <strong>
          {selectedFilters.map((filter) => filter.name).join(", ")}
        </strong>
      </Filters>
      <SidebarHeader>
        Change Report {loading && <Spinner />}
      </SidebarHeader>

      <NavItem onClick={() => onViewChange("summary")} active={selected == null}>
        Summary
      </NavItem>
      <Divider />

      <SidebarHeader>
        Culture Factors {loading && <Spinner />}
      </SidebarHeader>
      {progress && progress.totalCultureFactors > 0 && (
        <div style={{ fontSize: "12px", color: "#888", marginBottom: "5px", marginLeft: "5px" }}>
          {progress.processedCultureCount} of {progress.totalCultureFactors} loaded
        </div>
      )}
      {cultureFactors
        .sort((a, b) => getScoreChange(a, b))
        .map((factor) => {
          const changePercentage = (
            (factor.latest_score - factor.last_score) * 10
          ).toFixed(1);
          const changeColor =
            changePercentage >= 0
              ? "#2D70E2"
              : !isNaN(changePercentage)
              ? "#FF0000"
              : "#2A3039";

          return (
            <SubNavItem
              key={factor.factor_id}
              onClick={() => onSelectFactor({ ...factor, type: "culture" })}
              active={selected?.factor_id === factor.factor_id}
            >
              {factor.factor_name}
              <ChangePercentage style={{ color: changeColor }}>
                (
                {changePercentage >= 0 ? "+" : ""}
                {changePercentage}
                {!isNaN(changePercentage) && "%"}
                )
              </ChangePercentage>
            </SubNavItem>
          );
        })}
      <Divider />

      {outcomeFactors.length > 0 && (
        <>
          <SidebarHeader>
            Outcome Factors {loading && <Spinner />}
          </SidebarHeader>
          {progress && progress.totalOutcomeFactors > 0 && (
            <div style={{ fontSize: "12px", color: "#888", marginBottom: "5px", marginLeft: "5px" }}>
              {progress.processedOutcomeCount} of {progress.totalOutcomeFactors} loaded
            </div>
          )}
          {outcomeFactors
            .sort((a, b) => getScoreChange(a, b))
            .map((factor) => {
              const changePercentage =
                typeof factor.last_score !== "string"
                  ? ((factor.latest_score - factor.last_score) * 10).toFixed(1)
                  : "--";
              const changeColor =
                changePercentage >= 0
                  ? "#2D70E2"
                  : !isNaN(changePercentage)
                  ? "#FF0000"
                  : "#2A3039";

              return (
                <SubNavItem
                  key={factor.outcome_id}
                  onClick={() => onSelectFactor({ ...factor, type: "outcome" })}
                >
                  {factor.outcome_name}
                  <ChangePercentage style={{ color: changeColor }}>
                    (
                    {changePercentage >= 0 ? "+" : ""}
                    {changePercentage}
                    {!isNaN(changePercentage) && "%"}
                    )
                  </ChangePercentage>
                </SubNavItem>
              );
            })}
        </>
      )}
    </SidebarContainer>
  );
}

export default Sidebar;
