import React, { useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import html2canvas from 'html2canvas';
import { Icon } from 'semantic-ui-react'; // <-- Add an icon library of your choice

// Raleway + base styles
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    background-color: #f9fafc;
    color: #333;
  }
`;

export default function ThematicCards({ data, factor, filters }) {
  const cardRefs = useRef([]); // Create a ref array for all cards

  const captureCardImage = async (index, themeName) => {
    const cardRef = cardRefs.current[index];
    if (!cardRef) return;

    const canvas = await html2canvas(cardRef, {
      scale: 2, 
      backgroundColor: null,
    });

    const dataUrl = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `${themeName}-card.png`;
    link.click();
  };

  // Sort by descending count
  const sortedData = [...data].sort((a, b) => b.count - a.count);

  // Compute total for percentage calculations
  const totalCount = sortedData.reduce((acc, item) => acc + item.count, 0);

  // Determine display name of factor
  const factorName =
    factor.type === 'Overview'
      ? 'Overview'
      : factor.type === 'culture'
      ? factor.title
      : factor.name;

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        {/* Top Section with Factor Name */}
        <TopSection>
          <TopTitle>{factorName} Themes</TopTitle>
        </TopSection>

        {/* Filters (optional) */}
        <FilterNames>
          {filters.map((filter, idx) => (
            <FilterName key={idx}>{filter.name}</FilterName>
          ))}
        </FilterNames>

        <CardsContainer>
          {sortedData.map((item, idx) => {
            const percentage =
              totalCount > 0
                ? ((item.count / totalCount) * 100).toFixed(1)
                : 0;

            return (
              <CardWrapper 
                key={idx}
                ref={(el) => (cardRefs.current[idx] = el)}
              >
                <Card>
                  {/* ====== Download Icon ====== */}
                  <DownloadIcon onClick={() => captureCardImage(idx, item.theme)}>
                    <Icon name="download" />
                  </DownloadIcon>

                  {/* ====== Top Progress Bar & Percentage ====== */}
                  <ProgressBarRow>
                    <ProgressBarContainer>
                      <ProgressBarFill style={{ width: `${percentage}%` }} />
                    </ProgressBarContainer>
                    <ProgressInfo>
                      <PercentText>{percentage}%</PercentText>
                      <CountText>
                        {item.count}/{totalCount} responses
                      </CountText>
                    </ProgressInfo>
                  </ProgressBarRow>

                  {/* ====== Theme Title ====== */}
                  <ThemeTitle>{item.theme}</ThemeTitle>

                  {/* ====== Overview Section ====== */}
                  <SectionTitle>Overview</SectionTitle>
                  <OverviewText>{item.description}</OverviewText>

                  {/* ====== Feedback Quotes ====== */}
                  <SectionTitle>Feedback Quotes</SectionTitle>
                  <QuotesBox>
                    {item.quotes.map((quote, qIdx) => (
                      <Quote key={qIdx}>{quote}</Quote>
                    ))}
                  </QuotesBox>
                </Card>
              </CardWrapper>
            );
          })}
        </CardsContainer>
      </Wrapper>
    </>
  );
}

/* ======================= Styled Components ======================= */

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-sizing: border-box;
`;

const TopSection = styled.div`
  margin-bottom: 1.5rem;
`;

const TopTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2e2e2e;
  margin: 0;
`;

const FilterNames = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const FilterName = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #2d70e2;
  background-color: #f8faff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CardWrapper = styled.div`
  position: relative;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  font-family: 'Raleway', sans-serif;
`;

/* ========== Download Icon (top-right) ========== */
const DownloadIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: #2d70e2;
  opacity: 0.7;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;

/* ========== PROGRESS BAR / PERCENTAGE ========== */
const ProgressBarRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`;

const ProgressBarContainer = styled.div`
  flex-grow: 1;
  height: 4px;
  background-color: #ebf2ff; 
  border-radius: 4px;
  margin-right: 1rem;
`;

const ProgressBarFill = styled.div`
  height: 4px;
  border-radius: 4px;
  background-color: #2d70e2; 
  transition: width 0.3s ease;
`;

const ProgressInfo = styled.div`
  text-align: left;
`;

const PercentText = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: #2d70e2; 
`;

const CountText = styled.div`
  font-size: 0.85rem;
  color: #666;
`;

/* ========== THEME TITLE ========== */
const ThemeTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 1rem;
  font-family: 'Outfit', sans-serif;
  color: #2d70e2; 
  line-height: 35.84px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

/* ========== SECTION TITLES & TEXT ========== */
const SectionTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: #333;
  margin-bottom: 10px;
`;

const OverviewText = styled.p`
  font-size: 0.95rem;
  line-height: 1.5;
  color: #555;
  margin: 0;
  margin-bottom: 20px;
`;

/* ========== QUOTES ========== */
const QuotesBox = styled.div`
  margin-top: 1rem;
  border-left: 4px solid #eef;
  padding-left: 1rem;
  color: #2a3039;
  font-size: 12px;
  font-weight: 400;
`;

const Quote = styled.blockquote`
  font-style: italic;
  color: #2a3039;
  margin: 0 0 1rem 0;
  line-height: 1.4;
`;
