// ActionPlanningContext.js
import React, { createContext, useState, useContext } from 'react';
import { CopilotContext } from './CopilotContext'; // Where you call openAI
import { v4 as uuidv4 } from 'uuid';

// A new context that leverages CopilotContext to handle the conversation
export const ActionPlanContext = createContext(null);

export function ActionPlanProvider({ children }) {
  const {
    callOpenAI4, // or whichever function you want
    messages: globalMessages,
    setMessages: setGlobalMessages,
    // ... any other exports from CopilotContext
  } = useContext(CopilotContext);

  // We can track local messages for the "Action Plan" conversation only,
  // OR we can re-use global messages if you prefer a single chat log.
  const [actionPlanMessages, setActionPlanMessages] = useState([]);
  const [currentActionPlan, setCurrentActionPlan] = useState(null);

  // Adds a user message to the chat
  function addUserMessage(content) {
    const newMsg = { id: uuidv4(), role: 'user', content };
    setActionPlanMessages((prev) => [...prev, newMsg]);
    return newMsg;
  }

  // Adds an assistant message to the chat
  function addAssistantMessage(content) {
    const newMsg = { id: uuidv4(), role: 'assistant', content };
    setActionPlanMessages((prev) => [...prev, newMsg]);
    return newMsg;
  }

  // The core function that sends the user’s prompt + conversation to the LLM
  async function sendToLLM(userPrompt, extraContext = '') {
    // Create a conversation array to send. 
    // Typically you'd add system instructions + relevant messages + new user prompt.
    const conversation = [
      {
        role: 'system',
        content: `You are a Culture Action Planning Agent. 
         - You ask clarifying questions if you don't have enough context.
         - You produce a thorough, step-by-step action plan for the user’s need.
         - If you need more data from user, politely request it.
         - If you have enough context, provide final action plan in a structured way.`
      },
      // existing messages so LLM has the conversation history
      ...actionPlanMessages,
      // new user message
      { role: 'user', content: userPrompt }
    ];

    try {
      // call OpenAI with conversation
      const assistantMessageContent = await callOpenAI4(JSON.stringify(conversation));

      // Save the assistant message in state
      const newAssistantMsg = addAssistantMessage(assistantMessageContent);

      // [Optional] You could parse the response for special triggers, JSON, etc.
      // E.g. if the agent wants to “ask for more context,” or “execute a final plan,” etc.

      return newAssistantMsg;
    } catch (error) {
      console.error('Error with sendToLLM:', error);
    }
  }

  const value = {
    actionPlanMessages,
    addUserMessage,
    addAssistantMessage,
    sendToLLM,
    currentActionPlan,
    setCurrentActionPlan
  };

  return (
    <ActionPlanContext.Provider value={value}>
      {children}
    </ActionPlanContext.Provider>
  );
}
