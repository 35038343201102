import NoData from 'assets/images/UI/no_data.svg'
import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";

const Empty = () => {

    return (
        <Container>
            <img src={NoData} style={{width:200}}/>
            <Text>No data.</Text>
            <Description>
                Looks like there is no change data available for this selection.
            </Description>
        </Container>
    )
}

export default Empty;



const Container = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:50px;
    margin-bottom:50px;
    height:500px;
    width:100%;
    flex-direction:column;
    color:#2A3039;
    font-size:18px;
    font-weight:bold;
`

const Text = styled.div`
    color:#2A3039;
    font-size:20px;
    font-weight:600;
    margin-top:30px;
`

const Description = styled.div`
    color:#666D79;
    font-size:16px;
    font-weight:400;
    line-height:1.5;
    margin-top:20px;
    max-width:600px;
    text-align:center;
`