import React from 'react';
import styled from 'styled-components';
import { ICON_SET } from 'new_components/atoms/Icon/IconSet';
import IconWithBackground from 'new_components/molecules/IconWithBackground';
import Icon from 'new_components/atoms/Icon';


export default function SurveySelection({ setStep }) {
  return (
    <Container>
      <Title>Create a New Survey</Title>
      <Description>Please select the type of survey you’d like to create.</Description>

      <CardContainer>
        {/* ----- CARD 1: Factor Driven ----- */}
        <Card onClick={() => setStep(2)}>
          <IconWithBackground name={ICON_SET.layers} bgColor={'#EBF1FD'} />
          <CardTitle>Factor Driven</CardTitle>
          <CardDescription>
            Focus on key factors and drivers that matter most to your organization.
          </CardDescription>
          <Action>
            Select <Icon name={ICON_SET.chevronRight} bgColor={'#2D70E2'} size={16} />
          </Action>
        </Card>

        {/* ----- CARD 2: Simple ----- */}
        <Card onClick={() => setStep(2)}>
          <IconWithBackground name={ICON_SET.aiIcon} bgColor={'#EBF1FD'} />
          <CardTitle>Simple</CardTitle>
          <CardDescription>
            Get quick insights with a short, straightforward set of questions.
          </CardDescription>
          <Action>
            Select <Icon name={ICON_SET.chevronRight} bgColor={'#2D70E2'} size={16} />
          </Action>
        </Card>

        {/* ----- CARD 3: Slack Pulse ----- */}
        <Card onClick={() => setStep(2)}>
          <IconWithBackground name={ICON_SET.layers} bgColor={'#EBF1FD'} />
          <CardTitle>Slack Pulse</CardTitle>
          <CardDescription>
            Send out a quick Slack-based pulse to gather real-time feedback.
          </CardDescription>
          <Action>
            Select <Icon name={ICON_SET.chevronRight} bgColor={'#2D70E2'} size={16} />
          </Action>
        </Card>
      </CardContainer>
    </Container>
  );
}

// ----- Styled Components -----
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  margin-top: 20px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.div`
  margin-bottom: 20px;
  max-width: 700px;
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
`;

const Card = styled.div`
  flex: 1 1 calc(33% - 20px);
  background-color: #f8faff;
  border-radius: 5px;
  padding: 20px;
  min-width: 250px;
  cursor: pointer;

  &:hover {
    background-color: #eef4ff;
  }
`;

const CardTitle = styled.div`
  color: #2a3039;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const CardDescription = styled.div`
  color: #706c85;
  font-size: 12px;
  min-height: 75px;
`;

const Action = styled.div`
  color: #2d70e2;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
