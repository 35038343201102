import React, { useState } from "react";
import {
  Grid,
  Menu,
  Form,
  Input,
  Button,
  Dropdown,
  Segment,
  Checkbox,
  Icon,
  Confirm,
  Table,
  List,
  Placeholder
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import styled from "styled-components";

/* ========================= Styled Components ========================= */

const ManagerWrapper = styled(Segment)`
  &&& {
    margin-top: 2em;
  }
`;

const TitleSegment = styled(Segment)`
  &&& {
    background-color: #f5f7f9;
    box-shadow: none;
    border: 1px solid #e8e9e9;
    margin-bottom: 1em;
  }
`;

const AddNewHeader = styled.h4`
  margin-bottom: 0.5em;
`;

const ItemActions = styled.div`
  display: flex;
  gap: 0.3em;
  margin-left: auto;
`;

/* A bit of spacing for inline editing */
const EditInput = styled(Input)`
  &&& {
    margin-right: 0.5em;
    max-width: 200px;
  }
`;

/* If you want to further style the toggle or add transitions, do it here */
const OutcomeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

/* ========================= LibraryManager ========================= */
const LibraryManager = ({ library, setLibrary }) => {
  const [activeTab, setActiveTab] = useState("dimensions");

  // Controlled input for adding a new dimension/factor
  const [newItem, setNewItem] = useState("");

  // Editing states
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState("");

  // For confirming deletes
  const [deleteIndex, setDeleteIndex] = useState(null); // store index of item to delete
  const [showConfirm, setShowConfirm] = useState(false);

  /* ========================= Handlers ========================= */

  const handleTabClick = (tabName) => {
    // reset everything upon tab switch (optional)
    setActiveTab(tabName);
    setEditingIndex(null);
    setEditingValue("");
    setNewItem("");
  };

  const handleAdd = () => {
    if (!newItem.trim()) return;

    if (activeTab === "dimensions") {
      // Add dimension
      setLibrary((prev) => ({
        ...prev,
        dimensions: [...prev.dimensions, { value: newItem, label: newItem }],
      }));
    } else if (activeTab === "factors") {
      // Add factor
      setLibrary((prev) => ({
        ...prev,
        factors: [
          ...prev.factors,
          { value: newItem, label: newItem, dimension: "", outcome: false },
        ],
      }));
    }
    setNewItem("");
  };

  const handleRemove = (index) => {
    setLibrary((prev) => {
      const updated = { ...prev };

      updated[activeTab] = updated[activeTab].filter((_, i) => i !== index);

      // If removing a dimension, also remove its references from factors
      if (activeTab === "dimensions") {
        const removedDimension = prev.dimensions[index].value;
        updated.factors = updated.factors.map((f) =>
          f.dimension === removedDimension ? { ...f, dimension: "" } : f
        );
      }
      return updated;
    });
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      handleRemove(deleteIndex);
    }
    setDeleteIndex(null);
    setShowConfirm(false);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditingValue(library[activeTab][index].label);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditingValue("");
  };

  const handleUpdate = () => {
    if (!editingValue.trim()) return;

    setLibrary((prev) => {
      const updated = { ...prev };
      updated[activeTab] = updated[activeTab].map((item, idx) =>
        idx === editingIndex
          ? { ...item, value: editingValue, label: editingValue }
          : item
      );
      return updated;
    });
    setEditingIndex(null);
    setEditingValue("");
  };

  const updateFactorDimension = (index, dimensionValue) => {
    setLibrary((prev) => {
      const updatedFactors = prev.factors.map((factor, idx) =>
        idx === index ? { ...factor, dimension: dimensionValue } : factor
      );
      return { ...prev, factors: updatedFactors };
    });
  };

  const toggleFactorOutcome = (index) => {
    setLibrary((prev) => {
      const updatedFactors = prev.factors.map((factor, idx) =>
        idx === index ? { ...factor, outcome: !factor.outcome } : factor
      );
      return { ...prev, factors: updatedFactors };
    });
  };

  /* ========================= Renderers ========================= */

  const renderDimensions = () => {
    const { dimensions } = library;

    if (!dimensions.length) {
      return (
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line length="very short" />
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      );
    }

    return (
      <List divided relaxed>
        {dimensions.map((dim, idx) => (
          <List.Item key={idx}>
            <List.Content floated="right">
              {editingIndex === idx ? (
                <>
                  <EditInput
                    value={editingValue}
                    onChange={(e) => setEditingValue(e.target.value)}
                  />
                  <Button
                    icon="check"
                    color="green"
                    size="small"
                    onClick={handleUpdate}
                  />
                  <Button
                    icon="cancel"
                    color="grey"
                    size="small"
                    onClick={handleCancelEdit}
                  />
                </>
              ) : (
                <ItemActions>
                  <Button
                    icon="edit"
                    size="small"
                    onClick={() => handleEdit(idx)}
                  />
                  <Button
                    icon="trash"
                    size="small"
                    negative
                    onClick={() => {
                      setDeleteIndex(idx);
                      setShowConfirm(true);
                    }}
                  />
                </ItemActions>
              )}
            </List.Content>
            <List.Content>
              {editingIndex === idx ? (
                <strong style={{ color: "#888" }}>
                  Currently Editing...
                </strong>
              ) : (
                <List.Header as="span">{dim.label}</List.Header>
              )}
            </List.Content>
          </List.Item>
        ))}
      </List>
    );
  };

  const renderFactors = () => {
    const { factors, dimensions } = library;

    if (!factors.length) {
      return (
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line length="very short" />
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      );
    }

    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Factor</Table.HeaderCell>
            <Table.HeaderCell>Dimension</Table.HeaderCell>
            <Table.HeaderCell>Outcome?</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {factors.map((fac, idx) => {
            const isEditing = editingIndex === idx;
            return (
              <Table.Row key={idx}>
                <Table.Cell>
                  {isEditing ? (
                    <EditInput
                      value={editingValue}
                      onChange={(e) => setEditingValue(e.target.value)}
                    />
                  ) : (
                    fac.label
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Select Dimension"
                    selection
                    clearable
                    options={dimensions.map((dim) => ({
                      key: dim.value,
                      text: dim.label,
                      value: dim.value,
                    }))}
                    value={fac.dimension || ""}
                    onChange={(e, { value }) =>
                      updateFactorDimension(idx, value)
                    }
                    disabled={isEditing}
                  />
                </Table.Cell>
                <Table.Cell>
                  <OutcomeToggleWrapper>
                    <Checkbox
                      toggle
                      checked={fac.outcome}
                      onChange={() => toggleFactorOutcome(idx)}
                      disabled={isEditing}
                    />
                  </OutcomeToggleWrapper>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {isEditing ? (
                    <>
                      <Button
                        icon="check"
                        color="green"
                        size="small"
                        onClick={handleUpdate}
                      />
                      <Button
                        icon="cancel"
                        color="grey"
                        size="small"
                        onClick={handleCancelEdit}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        icon="edit"
                        size="small"
                        onClick={() => handleEdit(idx)}
                      />
                      <Button
                        icon="trash"
                        size="small"
                        negative
                        onClick={() => {
                          setDeleteIndex(idx);
                          setShowConfirm(true);
                        }}
                      />
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <ManagerWrapper>
      {/* Delete confirmation modal */}
      <Confirm
        open={showConfirm}
        header={`Delete ${activeTab.slice(0, -1)}?`}
        content="Are you sure you want to delete this item?"
        onCancel={() => {
          setShowConfirm(false);
          setDeleteIndex(null);
        }}
        onConfirm={handleConfirmDelete}
      />

      {/* Top menu to switch between dimensions/factors */}
      <Menu pointing secondary>
        <Menu.Item
          name="dimensions"
          active={activeTab === "dimensions"}
          onClick={() => handleTabClick("dimensions")}
        >
          Dimensions
        </Menu.Item>
        <Menu.Item
          name="factors"
          active={activeTab === "factors"}
          onClick={() => handleTabClick("factors")}
        >
          Factors
        </Menu.Item>
      </Menu>

      {/* Add New form */}
      <TitleSegment>
        <AddNewHeader>Add New {activeTab.slice(0, -1)}</AddNewHeader>
        <Form>
          <Form.Field>
            <Input
              placeholder={`Enter new ${activeTab.slice(0, -1)} name...`}
              value={newItem}
              onChange={(e) => setNewItem(e.target.value)}
              action={{
                color: "blue",
                icon: "add",
                content: "Add",
                onClick: handleAdd,
              }}
            />
          </Form.Field>
        </Form>
      </TitleSegment>

      {/* Display the list or table depending on activeTab */}
      <Segment>
        {activeTab === "dimensions" ? renderDimensions() : renderFactors()}
      </Segment>
    </ManagerWrapper>
  );
};

export default LibraryManager;
