// StepForm.jsx
import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";

// ======== Styled Components (same as before) ========
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  color: #2a3039;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const FormField = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const StyledInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #2d70e2;
  }
`;

const StyledSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #2d70e2;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const PrimaryButton = styled.button`
  background-color: #2d70e2;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #1c4db8;
  }
`;

const SecondaryButton = styled.button`
  background-color: #eee;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;

// Extend the question types with "Factor Follow Up"
const questionTypeOptions = [
  { key: "quant", text: "Quantitative", value: "quantitative" },
  { key: "qual", text: "Qualitative", value: "qualitative" },
  { key: "follow", text: "Follow-up", value: "followup" },
  { key: "factorFollowup", text: "Factor Follow Up", value: "factorFollowup" },
];

const StepForm = ({ initialData, onSave, onCancel, library }) => {
  const [data, setData] = useState(initialData);
  // For quantitative questions only, we have a scale type radio group.
  const [scaleType, setScaleType] = useState(
    data.scale && data.scale.max === 10 ? "10" : "5"
  );

  // Build factor dropdown options from library
  const factorOptions = library.factors.map((fac) => ({
    key: fac.value,
    text: fac.label,
    value: fac.value,
  }));

  const handleChange = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  // When changing scale type (only for quantitative questions)
  const handleScaleTypeChange = (value) => {
    setScaleType(value);
    setData((prev) => ({
      ...prev,
      scale: {
        min: 1,
        max: Number(value),
      },
    }));
  };

  // For Factor Follow Ups, we want to build trigger score options.
  // In a real app, you might use the selected factor’s scale.
  // Here we assume a default 0-10 range.
  const buildTriggerOptions = () => {
    const options = [];
    // For example, use 0-10. (Or adjust based on data.factor if needed.)
    for (let i = 0; i <= 10; i++) {
      options.push({ key: i, text: String(i), value: i });
    }
    return options;
  };

  const onSubmit = () => {
    onSave(data);
  };

  return (
    <FormContainer>
      <FormRow>
        <FormField>
          <Label>Question Type</Label>
          <StyledSelect
            value={data.type}
            onChange={(e) => handleChange("type", e.target.value)}
          >
            {questionTypeOptions.map((opt) => (
              <option value={opt.value} key={opt.key}>
                {opt.text}
              </option>
            ))}
          </StyledSelect>
        </FormField>

        <FormField>
          <Label>Factor</Label>
          <StyledSelect
            value={data.factor}
            onChange={(e) => handleChange("factor", e.target.value)}
          >
            <option value="">Select factor</option>
            {factorOptions.map((opt) => (
              <option value={opt.value} key={opt.key}>
                {opt.text}
              </option>
            ))}
          </StyledSelect>
        </FormField>
      </FormRow>

      <FormField>
        <Label>Question Text</Label>
        <StyledInput
          type="text"
          placeholder="Enter question text"
          value={data.text}
          onChange={(e) => handleChange("text", e.target.value)}
        />
      </FormField>

      {/* Only show Scale Type for quantitative questions */}
      {data.type === "quantitative" && (
        <>
          <Label>Scale Type</Label>
          <RadioGroup>
            <RadioLabel>
              <input
                type="radio"
                name="scale"
                value="5"
                checked={scaleType === "5"}
                onChange={() => handleScaleTypeChange("5")}
              />
              5-Point Scale (1 to 5)
            </RadioLabel>
            <RadioLabel>
              <input
                type="radio"
                name="scale"
                value="10"
                checked={scaleType === "10"}
                onChange={() => handleScaleTypeChange("10")}
              />
              10-Point Scale (1 to 10)
            </RadioLabel>
          </RadioGroup>
        </>
      )}

      {/* For Factor Follow Ups, add a multi-select for trigger scores */}
      {data.type === "factorFollowup" && (
        <>
          <Label>Trigger Factor Score(s)</Label>
          <Dropdown
            placeholder="Select Trigger Score(s)"
            fluid
            multiple
            selection
            options={buildTriggerOptions()}
            value={data.triggerValues || []}
            onChange={(e, { value }) => handleChange("triggerValues", value)}
          />
        </>
      )}

      <ButtonRow>
        <PrimaryButton onClick={onSubmit}>Save</PrimaryButton>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
      </ButtonRow>
    </FormContainer>
  );
};

export default StepForm;
