// CopilotContext.js
import React, { createContext, useState, useEffect } from 'react';
const axios = require('axios');


export const CopilotContext = createContext(null);

export function CopilotProvider({ children }) {
  // Basic chat states
  const [messages, setMessages] = useState([]);      // entire conversation
  const [phase, setPhase] = useState('idle');        // 'idle'|'deciding'|'decided'|'executing'
  const [decisionProcess, setDecisionProcess] = useState(null);
  const [promptText, setPromptText] = useState('');
  const [embeddings, setEmbeddings] = useState([]);

  /**
   * -------------------------------
   * 1) Call the OpenAI Chat API
   * -------------------------------
   * This function sends a message to the OpenAI API using fetch and
   * processes the streaming response. It appends tokens to the last
   * "assistant" message in our local state.
   */
 
  // CopilotContext.js (or wherever your streaming function lives)
async function streamOpenAI(userPrompt, { onDone } = {}) {
  try {
    setMessages((prev) => [...prev, { role: 'assistant', content: '' }]);
    setPhase('executing');

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer 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`,
      },
      body: JSON.stringify({
        model: 'gpt-4o', // or whichever
        messages: [{ role: 'user', content: userPrompt }],
        stream: true,
      }),
    });

    if (!response.ok) {
      throw new Error(`OpenAI API responded with status ${response.status}`);
    }

     // We'll store a local reference to the final messages:
     let finalMessages = [];

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;

      const chunkValue = decoder.decode(value, { stream: true });
      const lines = chunkValue.split('\n').filter((line) => line.trim() !== '');

      for (const line of lines) {
        if (line.trim() === 'data: [DONE]') {
          done = true;
          break;
        }

        const jsonStr = line.replace(/^data:\s*/, '');
        try {
          const parsed = JSON.parse(jsonStr);
          const token = parsed?.choices?.[0]?.delta?.content ?? '';

          if (token) {
            setMessages((prev) => {
              const lastIndex = prev.length - 1;
              if (lastIndex < 0) return prev;
              const lastMessage = prev[lastIndex];

              // If it's not an assistant message, create one
              if (lastMessage.role !== 'assistant') {
                return [...prev, { role: 'assistant', content: token }];
              } else {
                // Otherwise, append token to existing assistant content
                const updated = [...prev];
                updated[lastIndex] = {
                  ...lastMessage,
                  content: lastMessage.content + token,
                };

                finalMessages = updated;

                return updated;
              }
            });
          }
        } catch (err) {
          console.error('Error parsing stream data:', err);
        }
      }
    }

    // End of stream → only here do we set idle
    setPhase('idle');

    // **ONE final callback**: call onDone so we can auto‐save
    if (typeof onDone === 'function') {
      onDone(finalMessages);
    }

  } catch (error) {
    console.error('Error calling OpenAI:', error);
    setPhase('idle');
  }
}


  /**
   * -------------------------------
   * 2) Send prompt to OpenAI
   * -------------------------------
   * This is just a convenience wrapper that you can call from your UI.
   */
  function sendPromptToOpenAI() {
    if (!promptText.trim()) {
      return;
    }
    callOpenAI(promptText);
    setPromptText('');
  }

  /**
   * -------------------------------
   * 3) Execute next step
   * -------------------------------
   * If you want to preserve the idea of "executing" a named process (like you had before),
   * you can still do so. This example just calls callOpenAI with some extra data.
   */

  function executeProcess({ prompt,processName }, { onDone } = {}) {
    const combinedPrompt = `Please execute the process "${processName}". User prompt: ${prompt}`;
    streamOpenAI(combinedPrompt, { onDone });
  }



  /**
   * -------------------------------
   * 4) Clear all conversation data
   * -------------------------------
   */
  function clearMessages() {
    setMessages([]);
    setDecisionProcess(null);
    setPhase('idle');
  }

  /**
   * -------------------------------
   * 5) Start "Risk Assessment"
   * -------------------------------
   * Example function to demonstrate adding a custom role message to the conversation.
   */
  const startRisk = () => {
    console.log('Risk Assessment');
    setMessages((prev) => [...prev, { role: 'function', type: 'Risk Assessment' }]);
  };


  async function getBatchEmbeddings(texts, model="text-embedding-ada-002", apiKey="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", batchSize = 10) {
    const endpoint = 'https://api.openai.com/v1/embeddings';
  
    // Function to process a single batch
    const processBatch = async (batch) => {
      try {
        const response = await axios.post(
          endpoint,
          {
            input: batch,
            model: model,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        console.error('Error processing batch:', error.response?.data || error.message);
        throw error;
      }
    };
  
    // Split texts into batches
    const batches = [];
    for (let i = 0; i < texts.length; i += batchSize) {
      batches.push(texts.slice(i, i + batchSize));
    }
  
    // Process each batch sequentially or in parallel (based on requirements)
    const results = [];
    for (const batch of batches) {
      const batchResults = await processBatch(batch);
      results.push(...batchResults);
    }
  
    return results;
  }


  async function callOpenAI(userPrompt) {
    try {
      // Update state: user message (optional, depends on your state management)
      // setMessages((prev) => [...prev, { role: 'user', content: userPrompt }]);
  
      // Make request to OpenAI without streaming
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`,
        },
        body: JSON.stringify({
          model: 'o3-mini',
          reasoning_effort:'low',
          seed:1,
          messages: [
            // Optionally add a system message here for context
            { role: 'user', content: userPrompt },
          ],
          stream: false,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`OpenAI API responded with status ${response.status}`);
      }

  
      const data = await response.json();
      const assistantMessage = data.choices?.[0]?.message?.content || '';

  
      // Update state: assistant message
      // setMessages((prev) => [...prev, { role: 'assistant', content: assistantMessage }]);
  
      return assistantMessage;
    } catch (error) {
      console.error('Error calling OpenAI:', error);
      throw error;
    }
  }

  

  async function callOpenAI4(userPrompt) {
    try {
      // Update state: user message (optional, depends on your state management)
      // setMessages((prev) => [...prev, { role: 'user', content: userPrompt }]);
  
      // Make request to OpenAI without streaming
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`,
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          seed:1,
          messages: [
            // Optionally add a system message here for context
            { role: 'user', content: userPrompt },
          ],
          stream: false,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`OpenAI API responded with status ${response.status}`);
      }

  
      const data = await response.json();
      const assistantMessage = data.choices?.[0]?.message?.content || '';

  
      // Update state: assistant message
      // setMessages((prev) => [...prev, { role: 'assistant', content: assistantMessage }]);
  
      return assistantMessage;
    } catch (error) {
      console.error('Error calling OpenAI:', error);
      throw error;
    }
  }


  
  async function callOpenAIJSON(userPrompt) {
    try {
      // Update state: user message (optional, depends on your state management)
      // setMessages((prev) => [...prev, { role: 'user', content: userPrompt }]);
  
      // Make request to OpenAI without streaming
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`,
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          response_format: {type:'json_object'},
          seed:1,
          messages: [
            // Optionally add a system message here for context
            { role: 'user', content: userPrompt },
          ],
          stream: false,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`OpenAI API responded with status ${response.status}`);
      }

  
      const data = await response.json();
      const assistantMessage = data.choices?.[0]?.message?.content || '';

  
      // Update state: assistant message
      // setMessages((prev) => [...prev, { role: 'assistant', content: assistantMessage }]);
  
      return assistantMessage;
    } catch (error) {
      console.error('Error calling OpenAI:', error);
      throw error;
    }
  }

  /**
   * -------------------------------
   * 6) Provide all context values
   * -------------------------------
   */
  const value = {
    messages,
    phase,
    decisionProcess,
    executeProcess,
    clearMessages,
    promptText,
    setPromptText,
    startRisk,
    setMessages,
    sendPromptToOpenAI,
    getBatchEmbeddings,
    callOpenAI,
    callOpenAIJSON,
    callOpenAI4
  };

  return (
    <CopilotContext.Provider value={value}>
      {children}
    </CopilotContext.Provider>
  );
}
