import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Menu, Popup, Modal, Icon as SUIcon } from "semantic-ui-react";

// Renamed imports to avoid collision
import CustomIcon from "new_components/atoms/Icon";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Our forms
import StepForm from "./StepForm";
import ConditionalForm from "./ConditionalForm";
import LibraryManager from "./LibraryManager";
import TranslationsTab from "./TranslationsTab"; // Import your new tab


/* ========================= Animations & Wrappers ========================= */
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-10px); }
  to   { opacity: 1; transform: translateY(0); }
`;

const PageWrapper = styled.div`
  font-family: "Raleway", sans-serif;
  padding: 2rem;
  color: #2a3039;
`;

const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  animation: ${fadeIn} 0.3s ease forwards;
`;

/* ========================= Card Styles ========================= */
const StepCardWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 5px;
  position: relative;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #f9fafc;
  }
`;

const CardActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const IconOnlyButton = styled.button`
  border: none;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: ${(props) => props.bgColor || "#dcecff"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const UpDownButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  i.icon {
    color: #666;
    transition: color 0.2s;
  }
  &:hover i.icon {
    color: #333;
  }
  &:disabled i.icon {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const AddStepButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0.5rem 0;
  background-color: #2d70e2;
  cursor: pointer;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: #fff;
  }

  &:hover {
    background-color: #1c4db8;
  }
`;

// A header for the Factor Follow Ups section
const SectionHeader = styled.h3`
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: #2d70e2;
`;

const QuestionContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

/* ========================= Helpers ========================= */
const createEmptyQuestion = () => ({
  id: Date.now(),
  type: "quantitative",
  text: "",
  factor: "",
  scale: { min: 0, max: 10, labelMin: "", labelMax: "" },
  conditionals: [],
  metadata: { order: 0, required: false },
});

/* ========================= WorkflowBuilder ========================= */
const WorkflowBuilder = ({ library, questions, setQuestions }) => {
  // Regular question editing states

  console.log(questions)
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [addingQuestionIndex, setAddingQuestionIndex] = useState(null);

  // Factor Follow Up editing states
  const [editingFactorFollowUpIndex, setEditingFactorFollowUpIndex] =
    useState(null);
  const [showFactorFollowUpModal, setShowFactorFollowUpModal] =
    useState(false);

  // Conditional editing state (unchanged)
  const [activeConditional, setActiveConditional] = useState(null);
  const [showConditionalModal, setShowConditionalModal] = useState(false);

  // Modals for regular questions
  const [showQuestionModal, setShowQuestionModal] = useState(false);

  // Build skip options for conditionals (for regular questions)
  const skipQuestionOptions = questions.map((q, idx) => ({
    key: q.id,
    text: q.text || `Question ${idx + 1}`,
    value: q.id,
  }));

   // 1) Helper: reassign each question's `id` to be its index + 1
   const reassignQuestionIds = (qArr) => {
    return qArr.map((q, idx) => ({
      ...q,
      id: idx + 1,
    }));
  };

  // 2) Wrapped setter:
  const setQuestionsSequential = (newQuestions) => {
    setQuestions(reassignQuestionIds(newQuestions));
  };

  // 3) Your existing logic:
  const handleAddStep = (index, stepData) => {
    const updated = [...questions];
    updated.splice(index, 0, stepData);
    setQuestionsSequential(updated);
  };

  const handleDeleteQuestion = (index) => {
    const updated = [...questions];
    updated.splice(index, 1);
    setQuestionsSequential(updated);
  };

  const handleMoveQuestion = (index, direction) => {
    const updated = [...questions];
    [updated[index], updated[index + direction]] = [
      updated[index + direction],
      updated[index],
    ];
    setQuestionsSequential(updated);
  };


  const handleEditStep = (index) => {
    setEditingQuestion(index);
    setShowQuestionModal(true);
  };



  const handleSaveQuestion = (formData) => {
    // Save for regular questions
    if (editingQuestion !== null) {
      const updated = [...questions];
      updated[editingQuestion] = formData;
      setQuestions(updated);
    } else if (addingQuestionIndex !== null) {
      handleAddStep(addingQuestionIndex, formData);
    }
    setEditingQuestion(null);
    setAddingQuestionIndex(null);
    setShowQuestionModal(false);
  };

  const closeQuestionModal = () => {
    setEditingQuestion(null);
    setAddingQuestionIndex(null);
    setShowQuestionModal(false);
  };

  /* -------------- Factor Follow Up Logic -------------- */
  const handleAddFactorFollowUp = () => {
    setEditingFactorFollowUpIndex(null);
    setShowFactorFollowUpModal(true);
  };

  const handleEditFactorFollowUp = (index) => {
    setEditingFactorFollowUpIndex(index);
    setShowFactorFollowUpModal(true);
  };

  const handleDeleteFactorFollowUp = (index) => {
    if (
      window.confirm("Are you sure you want to delete this factor follow up?")
    ) {
      const updated = questions.filter((q) => q.type === "factorFollowup");
      const others = questions.filter((q) => q.type !== "factorFollowup");
      updated.splice(index, 1);
      setQuestions([...others, ...updated]);
    }
  };

  const handleSaveFactorFollowUp = (formData) => {
    // When saving a factor follow up, always append it at the end.
    if (editingFactorFollowUpIndex !== null) {
      // Update existing follow up in the factor follow ups portion.
      const followUps = questions.filter((q) => q.type === "factorFollowup");
      followUps[editingFactorFollowUpIndex] = formData;
      const others = questions.filter((q) => q.type !== "factorFollowup");
      setQuestions([...others, ...followUps]);
    } else {
      // New factor follow up: append to end.
      setQuestions([...questions, { id: Date.now(), ...formData }]);
    }
    setShowFactorFollowUpModal(false);
    setEditingFactorFollowUpIndex(null);
  };

  const closeFactorFollowUpModal = () => {
    setShowFactorFollowUpModal(false);
    setEditingFactorFollowUpIndex(null);
  };

  /* -------------- Conditional Logic (unchanged) -------------- */
  const handleAddConditional = (questionIndex) => {
    setActiveConditional({
      questionIndex,
      conditionalIndex: null,
      isNew: true,
    });
    setShowConditionalModal(true);
  };

  const handleEditConditional = (questionIndex, conditionalIndex) => {
    setActiveConditional({
      questionIndex,
      conditionalIndex,
      isNew: false,
    });
    setShowConditionalModal(true);
  };

  const handleDeleteConditional = (questionIndex, conditionalIndex) => {
    const updated = [...questions];
    updated[questionIndex].conditionals.splice(conditionalIndex, 1);
    setQuestions(updated);
  };

  const handleSaveConditional = (conditionalData) => {
    if (!activeConditional) return;
    const { questionIndex, conditionalIndex, isNew } = activeConditional;
    const updated = [...questions];
    if (isNew) {
      updated[questionIndex].conditionals.push({
        id: Date.now(),
        ...conditionalData,
      });
    } else {
      updated[questionIndex].conditionals[conditionalIndex] = {
        ...updated[questionIndex].conditionals[conditionalIndex],
        ...conditionalData,
      };
    }
    setQuestions(updated);
    setActiveConditional(null);
    setShowConditionalModal(false);
  };

  const closeConditionalModal = () => {
    setActiveConditional(null);
    setShowConditionalModal(false);
  };


  // Split questions into regular and factor follow ups.
  const regularQuestions = questions.filter(
    (q) => q.type !== "factorFollowup"
  );
  const factorFollowUps = questions.filter(
    (q) => q.type === "factorFollowup"
  );

  return (
    <>
      {/* Render regular questions */}
      <QuestionContainer>
      {regularQuestions.map((q, idx) => {
        const isLast = idx === regularQuestions.length - 1;
        const questionNumber = idx + 1;
        return (
          <React.Fragment key={q.id}>
            <StepCardWrapper onClick={() => handleEditStep(idx)}>
              <div style={{ fontSize: "15px", fontWeight: "600" }}>
                {questionNumber}. {q.text || "Untitled Question"}
                {q.factor && (
                  <span
                    style={{
                      marginLeft: 10,
                      color: "#888",
                      fontWeight: 500,
                    }}
                  >
                    [Factor: {q.factor}]
                  </span>
                )}
              </div>
              <CardActionsContainer>
                <Popup
                  content="Delete this question"
                  trigger={
                    <IconOnlyButton
                      bgColor="#ffe2e2"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteQuestion(idx);
                      }}
                    >
                      <CustomIcon name={ICON_SET.delete} size={16} />
                    </IconOnlyButton>
                  }
                />
                <Popup
                  content="Add Conditional"
                  trigger={
                    <IconOnlyButton
                      bgColor="#dcecff"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddConditional(idx);
                      }}
                    >
                      <CustomIcon name={ICON_SET.layers} size={16} />
                    </IconOnlyButton>
                  }
                />
                {regularQuestions.length > 1 && !isLast && (
                  <Popup
                    content="Add Question"
                    trigger={
                      <IconOnlyButton
                        bgColor="#ffe8c2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAddingQuestionIndex(idx + 1);
                          setShowQuestionModal(true);
                        }}
                      >
                        <CustomIcon name={ICON_SET.add} size={16} />
                      </IconOnlyButton>
                    }
                  />
                )}
                <Popup
                  content="Move question up"
                  trigger={
                    <UpDownButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveQuestion(idx, -1);
                      }}
                      disabled={idx === 0}
                    >
                      <SUIcon name="chevron up" />
                    </UpDownButton>
                  }
                />
                <Popup
                  content="Move question down"
                  trigger={
                    <UpDownButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveQuestion(idx, 1);
                      }}
                      disabled={idx === regularQuestions.length - 1}
                    >
                      <SUIcon name="chevron down" />
                    </UpDownButton>
                  }
                />
              </CardActionsContainer>
            </StepCardWrapper>

            {/* Conditionals for each regular question */}
            {q.conditionals.map((cond, cIdx) => (
              <div
                key={cond.id}
                style={{
                  width: 600,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <div style={{ backgroundColor: "#f9fafc", border: "1px solid #eee", borderRadius: 6, padding: 16 }}>
                  <div style={{ marginBottom: 8 }}>
                    <strong>{cond.type}</strong> —{" "}
                    {cond.type === "skip"
                      ? `Trigger: [${cond.triggerValues}] → Skip: [${(
                          cond.skipQuestionIds || []
                        ).join(", ")}]`
                      : `Trigger: ${cond.trigger} → Content: ${cond.content}`}
                  </div>
                  <div>
                    <Popup
                      content="Edit Conditional"
                      trigger={
                        <IconOnlyButton
                          bgColor="#dcecff"
                          onClick={() => handleEditConditional(idx, cIdx)}
                        >
                          <CustomIcon name={ICON_SET.edit} size={16} />
                        </IconOnlyButton>
                      }
                    />
                    <Popup
                      content="Delete Conditional"
                      trigger={
                        <IconOnlyButton
                          bgColor="#ffe2e2"
                          onClick={() => handleDeleteConditional(idx, cIdx)}
                        >
                          <CustomIcon name={ICON_SET.delete} size={16} />
                        </IconOnlyButton>
                      }
                    />
                  </div>
                </div>
              </div>
            ))}

            {(isLast || regularQuestions.length === 1) && (
              <AddStepButton
                onClick={() => {
                  setAddingQuestionIndex(idx + 1);
                  setShowQuestionModal(true);
                }}
              >
                <CustomIcon name={ICON_SET.add} size={20} />
              </AddStepButton>
            )}
          </React.Fragment>
        );
      })}

      {regularQuestions.length === 0 && (
        <AddStepButton
          onClick={() => {
            setAddingQuestionIndex(0);
            setShowQuestionModal(true);
          }}
          title="Add First Question"
        >
          <CustomIcon name={ICON_SET.add} size={20} />
        </AddStepButton>
      )}

    </QuestionContainer>

      {/* ================== QUESTION MODAL ================== */}
      <Modal
        open={showQuestionModal}
        onClose={closeQuestionModal}
        size="small"
        closeIcon
      >
        <Modal.Header>
          {editingQuestion !== null ? "Edit Question" : "Add Question"}
        </Modal.Header>
        <Modal.Content>
          <StepForm
            initialData={
              editingQuestion !== null
                ? regularQuestions[editingQuestion]
                : createEmptyQuestion()
            }
            onSave={handleSaveQuestion}
            onCancel={closeQuestionModal}
            library={library}
          />
        </Modal.Content>
      </Modal>

      {/* ================== FACTOR FOLLOW UP SECTION ================== */}
      <SectionHeader>Factor Follow Ups</SectionHeader>
      {factorFollowUps.map((q, idx) => (
        <StepCardWrapper key={q.id} onClick={() => handleEditFactorFollowUp(idx)}>
          <div style={{ fontSize: "15px", fontWeight: "600" }}>
            Factor Follow Up: {q.text || "Untitled Follow Up"}
            {q.factor && (
              <span style={{ marginLeft: 10, color: "#888", fontWeight: 500 }}>
                [Factor: {q.factor}]
              </span>
            )}
          </div>
          <CardActionsContainer>
            <Popup
              content="Delete this follow up"
              trigger={
                <IconOnlyButton
                  bgColor="#ffe2e2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFactorFollowUp(idx);
                  }}
                >
                  <CustomIcon name={ICON_SET.delete} size={16} />
                </IconOnlyButton>
              }
            />
            <Popup
              content="Move follow up up/down"
              trigger={
                <UpDownButton disabled>
                  {/* Optionally add up/down icons if you want to allow reordering within follow ups */}
                  <SUIcon name="arrows alternate vertical" />
                </UpDownButton>
              }
            />
          </CardActionsContainer>
        </StepCardWrapper>
      ))}

      <AddStepButton onClick={handleAddFactorFollowUp}>
        <CustomIcon name={ICON_SET.add} size={20} />
      </AddStepButton>

      {/* ================== FACTOR FOLLOW UP MODAL ================== */}
      <Modal
        open={showFactorFollowUpModal}
        onClose={closeFactorFollowUpModal}
        size="small"
        closeIcon
      >
        <Modal.Header>
          {editingFactorFollowUpIndex !== null
            ? "Edit Factor Follow Up"
            : "Add Factor Follow Up"}
        </Modal.Header>
        <Modal.Content>
          <StepForm
            // For factor follow ups, preset the type to "factorFollowup"
            initialData={
              editingFactorFollowUpIndex !== null
                ? factorFollowUps[editingFactorFollowUpIndex]
                : { type: "factorFollowup", triggerValues: [], factor: "", text: "" }
            }
            onSave={handleSaveFactorFollowUp}
            onCancel={closeFactorFollowUpModal}
            library={library}
          />
        </Modal.Content>
      </Modal>

      {/* ================== CONDITIONAL MODAL (unchanged) ================== */}
      {activeConditional && (() => {
        const currentQuestion = questions[activeConditional.questionIndex];
        const filteredSkipOptions = questions
          .slice(activeConditional.questionIndex + 1)
          .map((q, idx) => ({
            key: q.id,
            text: q.text || `Question ${activeConditional.questionIndex + idx + 2}`,
            value: q.id,
          }));
        return (
          <Modal
            open={showConditionalModal}
            onClose={closeConditionalModal}
            size="small"
            closeIcon
          >
            <Modal.Header>
              {activeConditional.isNew ? "Add Conditional" : "Edit Conditional"}
            </Modal.Header>
            <Modal.Content>
              <ConditionalForm
                initialData={
                  activeConditional.isNew
                    ? { type: "", triggerValues: "", skipQuestionIds: [] }
                    : currentQuestion.conditionals[activeConditional.conditionalIndex] || {}
                }
                onSave={handleSaveConditional}
                onCancel={closeConditionalModal}
                skipOptions={filteredSkipOptions}
                currentQuestionScale={currentQuestion.scale}
              />
            </Modal.Content>
          </Modal>
        );
      })()}
    </>
  );
};


const SurveyBuilderApp = () => {
    const [library, setLibrary] = useState({
      dimensions: [
        { value: "Employee Engagement", label: "Employee Engagement" },
        { value: "Workplace Culture", label: "Workplace Culture" },
      ],
      factors: [
        {
          value: "Customer Satisfaction",
          label: "Customer Satisfaction",
          dimension: "Employee Engagement",
          outcome: true,
        },
        {
          value: "Innovation",
          label: "Innovation",
          dimension: "Workplace Culture",
          outcome: false,
        },
      ],
    });
    const [questions, setQuestions] = useState([]);
    const [activeTab, setActiveTab] = useState("workflow");
  
    return (
      <PageWrapper>
        <Menu pointing secondary>
          <Menu.Item
            name="workflow"
            active={activeTab === "workflow"}
            onClick={() => setActiveTab("workflow")}
          >
            Questions Builder
          </Menu.Item>
          <Menu.Item
            name="library"
            active={activeTab === "library"}
            onClick={() => setActiveTab("library")}
          >
            Factor Manager
          </Menu.Item>
          <Menu.Item
            name="translations"
            active={activeTab === "translations"}
            onClick={() => setActiveTab("translations")}
          >
            Translations
          </Menu.Item>
        </Menu>
  
        {activeTab === "workflow" ? (
          <WorkflowBuilder
            library={library}
            questions={questions}
            setQuestions={setQuestions}
          />
        ) : activeTab === "library" ? (
          <LibraryManager library={library} setLibrary={setLibrary} />
        ) : activeTab === "translations" ? (
          <TranslationsTab questions={questions} setQuestions={setQuestions} />
        ) : null}
      </PageWrapper>
    );
  };

export default SurveyBuilderApp;
