// TranslationsTab.jsx
import React, { useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, Dropdown } from "semantic-ui-react";

// Styled container for the tab
const TabContainer = styled.div`
  padding: 1rem;
  max-width: 800px;
  margin: auto;
`;

const LanguageSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const QuestionContainer = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 4px;
`;

const ConditionalContainer = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TranslationsTab = ({ questions, setQuestions }) => {
  // The currently selected language to edit
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // For adding a new language
  const [newLanguage, setNewLanguage] = useState("");

  // Derive a list of languages already used in the survey
  const languageSet = new Set();
  questions.forEach((q) => {
    if (q.translations) {
      Object.keys(q.translations).forEach((lang) => languageSet.add(lang));
    }
    if (q.conditionals) {
      q.conditionals.forEach((cond) => {
        if (cond.translations) {
          Object.keys(cond.translations).forEach((lang) => languageSet.add(lang));
        }
      });
    }
  });
  const availableLanguages = Array.from(languageSet).map((lang) => ({
    key: lang,
    text: lang,
    value: lang,
  }));

  // Handler to add a new language.
  const handleAddLanguage = () => {
    if (newLanguage && !languageSet.has(newLanguage)) {
      // Update every question and its conditionals to add an empty translation for the new language
      const updatedQuestions = questions.map((q) => {
        const newTranslations = { ...(q.translations || {}) };
        if (!newTranslations[newLanguage]) {
          newTranslations[newLanguage] = {
            text: ""
          };
        }
        const newConditionals = (q.conditionals || []).map((cond) => {
          const condTranslations = { ...(cond.translations || {}) };
          if (!condTranslations[newLanguage]) {
            condTranslations[newLanguage] = {
              trigger: "",
              content: ""
            };
          }
          return { ...cond, translations: condTranslations };
        });
        return { ...q, translations: newTranslations, conditionals: newConditionals };
      });
      setQuestions(updatedQuestions);
      setNewLanguage("");
      setSelectedLanguage(newLanguage);
    }
  };

  // Handler to update a question's translation for the selected language.
  const handleQuestionTranslationChange = (questionId, value) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === questionId) {
        const translations = { ...(q.translations || {}) };
        translations[selectedLanguage] = {
          ...(translations[selectedLanguage] || {}),
          text: value
        };
        return { ...q, translations };
      }
      return q;
    });
    setQuestions(updatedQuestions);
  };

  // Handler to update a conditional's translation for the selected language.
  const handleConditionalTranslationChange = (questionId, conditionalId, field, value) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === questionId) {
        const newConditionals = (q.conditionals || []).map((cond) => {
          if (cond.id === conditionalId) {
            const translations = { ...(cond.translations || {}) };
            translations[selectedLanguage] = {
              ...(translations[selectedLanguage] || {}),
              [field]: value
            };
            return { ...cond, translations };
          }
          return cond;
        });
        return { ...q, conditionals: newConditionals };
      }
      return q;
    });
    setQuestions(updatedQuestions);
  };

  return (
    <TabContainer>
      <LanguageSelectContainer>
        <Dropdown
          placeholder="Select Language"
          selection
          options={availableLanguages}
          value={selectedLanguage}
          onChange={(e, { value }) => setSelectedLanguage(value)}
        />
        <Input
          placeholder="New language code (e.g., fr, es)"
          value={newLanguage}
          onChange={(e) => setNewLanguage(e.target.value)}
        />
        <Button primary onClick={handleAddLanguage}>
          Add Language
        </Button>
      </LanguageSelectContainer>
      {!selectedLanguage ? (
        <p>Please select a language to edit translations.</p>
      ) : (
        questions.map((q) => (
          <QuestionContainer key={q.id}>
            <h4>Question: {q.text}</h4>
            <Form>
              <Form.Field>
                <label>
                  Translation for Question Text ({selectedLanguage})
                </label>
                <Input
                  value={
                    (q.translations &&
                      q.translations[selectedLanguage] &&
                      q.translations[selectedLanguage].text) ||
                    ""
                  }
                  onChange={(e) =>
                    handleQuestionTranslationChange(q.id, e.target.value)
                  }
                />
              </Form.Field>
            </Form>
            {q.conditionals && q.conditionals.length > 0 && (
              <div>
                <h5>Conditionals:</h5>
                {q.conditionals.map((cond) => (
                  <ConditionalContainer key={cond.id}>
                    <p>
                      <strong>Conditional Type:</strong> {cond.type}
                    </p>
                    {/* For non-skip conditionals, assume there is trigger and content */}
                    {cond.type !== "skip" && (
                      <Form>
                        <Form.Field>
                          <label>
                            Trigger Translation ({selectedLanguage})
                          </label>
                          <Input
                            value={
                              (cond.translations &&
                                cond.translations[selectedLanguage] &&
                                cond.translations[selectedLanguage].trigger) ||
                              ""
                            }
                            onChange={(e) =>
                              handleConditionalTranslationChange(
                                q.id,
                                cond.id,
                                "trigger",
                                e.target.value
                              )
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>
                            Content Translation ({selectedLanguage})
                          </label>
                          <Input
                            value={
                              (cond.translations &&
                                cond.translations[selectedLanguage] &&
                                cond.translations[selectedLanguage].content) ||
                              ""
                            }
                            onChange={(e) =>
                              handleConditionalTranslationChange(
                                q.id,
                                cond.id,
                                "content",
                                e.target.value
                              )
                            }
                          />
                        </Form.Field>
                      </Form>
                    )}
                    {cond.type === "skip" && (
                      <p>
                        <em>
                          Skip conditions may not need additional translation.
                        </em>
                      </p>
                    )}
                  </ConditionalContainer>
                ))}
              </div>
            )}
          </QuestionContainer>
        ))
      )}
    </TabContainer>
  );
};

export default TranslationsTab;
