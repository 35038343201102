// FactorDetail.js
import React from "react";
import styled,{keyframes} from "styled-components";
import BarChart from "./BarChart";
import { formatElements } from "../../Functions/parseText";


const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-40px);}
  to   { opacity: 1; transform: translateY(0);}
`;

const Header = styled.h1`
  font-size: 24px;
  color: #2a3039;
  font-family: "Raleway", sans-serif;
  margin-bottom: 20px;
  margin-top:5px;
`;

const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;


const AnalysisText = styled.p`
  font-size: 14px;
  color: #2a3039;
  font-family: "Raleway", sans-serif;
`;

const Score = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-botom: 5px;
`;

const ChangeTag = styled.div`
  font-size: 24px;
  margin-left: 20px;
  border-radius: 20px;
  background-color: #d4f5ed;
  padding: 10px;
`;

const ScoreArea = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 30px;
`;



const Container = styled.div`
  font-family: "Raleway", sans-serif;
  margin-bottom: 50px;

  animation: ${fadeIn} 1.5s forwards;
`;



function FactorDetail({ factor }) {
  const { factor_name, outcome_name, score_history, gpt_analysis } = factor;

  const name = factor_name || outcome_name;
  const change_percentage = factor.latest_score - factor.last_score;
  const isPositive = change_percentage >= 0;

  return (
    <Container>
       <Title>Factor Change</Title>
      <Header>{name}</Header>

      <ScoreArea>
        Your {name.toLowerCase()} score is currently{" "}
        {(factor.latest_score * 10).toFixed(0)}% and{" "}
        {(!isNaN((change_percentage * 10).toFixed(0)) && (
          <strong style={{ marginLeft: 3 }}>
            {isPositive ? " increased" : " decreased"} by{" "}
            {(change_percentage * 10).toFixed(0)}%.
          </strong>
        )) || <strong>&nbsp; has not changed.</strong>}
      </ScoreArea>

      {!isNaN(change_percentage)? <BarChart factor={factor} /> :""}

      {/* <T2>How does this change compare?</T2>
    <Description>
        A {change_percentage.toFixed(2)}% change in {name} is in the 87th percentile of changes when compared to any change recorded in this area.
    </Description>
    <Progress percent={87} progress color="blue"/> */}

    {!isNaN(change_percentage)?<AnalysisText>{formatElements(gpt_analysis)}</AnalysisText> :""}
    </Container>
  );
}

export default FactorDetail;
