import React, { useState } from 'react';
import styled from 'styled-components';

import Home from './Home';
import Create from './Create';
import SurveySelection from './SurveySelection';


const SurveyHome = () =>{
    const [step, setStep] = useState(0);

   
    return (
        <Container>
            {step === 0 && <Home setStep={setStep} />}
            {step === 1 && <SurveySelection setStep={setStep} />}
            {step === 2 && <Create setStep={setStep} />}
        </Container>
    )
}

export default SurveyHome;

const Container = styled.div`

`