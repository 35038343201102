import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";

// ======== Styled Components (Matching StepForm Style) ========
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto; /* center horizontally */
  font-family: "Raleway", sans-serif;
  color: #2a3039;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const FormField = styled.div`
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;

/** Standard text input */
const StyledInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #2d70e2;
  }
`;

/** Multi-select styling for skip questions (if you prefer to keep a styled native select) */
const StyledMultiSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  min-height: 80px; /* tall enough for multiple items */
  &:focus {
    outline: none;
    border-color: #2d70e2;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const PrimaryButton = styled.button`
  background-color: #2d70e2;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #1c4db8;
  }
`;

const SecondaryButton = styled.button`
  background-color: #eee;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;

// ======== Options Data ========
const conditionalTypeOptions = [
  { key: "skip", text: "Skip Condition", value: "skip" },
  { key: "followup", text: "Follow-up", value: "followup" },
  { key: "role", text: "Role", value: "role" },
  { key: "ai", text: "AI Follow-up", value: "ai" },
];

/**
 * ConditionalForm
 *
 * Props:
 *  - initialData: initial form values.
 *  - onSave: function to call with the form data on submit.
 *  - onCancel: function to cancel the form.
 *  - skipOptions: an array of questions (for skipping) passed from the parent.
 *  - currentQuestionScale: an object representing the current question’s scale,
 *      e.g., { min: 0, max: 10 } or { min: 1, max: 5 }.
 */
const ConditionalForm = ({
  initialData,
  onSave,
  onCancel,
  skipOptions = [],
  currentQuestionScale,
}) => {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState("");

  // Update a field in the form data.
  const handleChange = (field, value) => {
    setData((prev) => ({ ...prev, [field]: value }));
  };

  // Generic handler for multi-select changes on native selects.
  const handleMultiSelectChange = (field, e) => {
    console.log(e.target.selectedOptions,e);
    const selected = Array.from(e.target.selectedOptions).map((o) => o.value);
    setData((prev) => ({ ...prev, [field]: selected }));
  };

  // Build options for trigger scores based on the current question's scale.
  // Semantic UI's Dropdown expects options with key, text, and value.
  const triggerOptions = [];
  let validMin, validMax;
  if (currentQuestionScale) {
    if (currentQuestionScale.max === 10) {
      validMin = 0;
      validMax = 10;
    } else if (currentQuestionScale.max === 5) {
      validMin = 1;
      validMax = 5;
    } else {
      validMin = currentQuestionScale.min;
      validMax = currentQuestionScale.max;
    }
  } else {
    // Fallback to 0-10 if no scale provided.
    validMin = 0;
    validMax = 10;
  }
  for (let i = validMin; i <= validMax; i++) {
    triggerOptions.push({ key: i, text: String(i), value: i });
  }

  const onSubmit = () => {
    // With a Dropdown multi-select for trigger scores, values are inherently valid.
    onSave(data);
  };

  return (
    <FormContainer>
      {/* Conditional Type */}
      <FormRow>
        <FormField>
          <Label>Conditional Type</Label>
          <select
            value={data.type || ""}
            onChange={(e) => handleChange("type", e.target.value)}
          >
            <option value="">-- Select --</option>
            {conditionalTypeOptions.map((opt) => (
              <option key={opt.key} value={opt.value}>
                {opt.text}
              </option>
            ))}
          </select>
        </FormField>
      </FormRow>

      {data.type === "skip" ? (
        <FormRow>
          <FormField>
            <Label>Trigger Score(s)</Label>
            <Dropdown
              placeholder="Select Trigger Score(s)"
              fluid
              multiple
              selection
              options={triggerOptions}
              value={data.triggerValues || []}
              onChange={(e, { value }) =>
                handleChange("triggerValues", value)
              }
            />
            {error && (
              <span style={{ color: "red", fontSize: "12px" }}>{error}</span>
            )}
          </FormField>
          <FormField>
            <Label>Skip Questions</Label>
            <StyledMultiSelect
              multiple
              value={data.skipQuestions || []}
              onChange={(e) => handleMultiSelectChange("skipQuestions", e)}
            >
              {skipOptions.map((opt) => (
                <option key={opt.key} value={opt.value}>
                  {opt.text}
                </option>
              ))}
            </StyledMultiSelect>
          </FormField>
        </FormRow>
      ) : (
        <FormRow>
          <FormField>
            <Label>Trigger Condition</Label>
            <StyledInput
              placeholder="Enter trigger condition (e.g. answer < 5)"
              value={data.trigger || ""}
              onChange={(e) => handleChange("trigger", e.target.value)}
            />
          </FormField>
          <FormField>
            <Label>Content</Label>
            <StyledInput
              placeholder="Enter conditional content"
              value={data.content || ""}
              onChange={(e) => handleChange("content", e.target.value)}
            />
          </FormField>
        </FormRow>
      )}

      {/* Save/Cancel Buttons */}
      <ButtonRow>
        <PrimaryButton onClick={onSubmit}>Save Conditional</PrimaryButton>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
      </ButtonRow>
    </FormContainer>
  );
};

export default ConditionalForm;
