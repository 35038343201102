import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { filter_data, get_overall_average } from "reports/Tag/calculations";
import { CopilotContext } from '../CopilotContext';
import { Loader } from 'semantic-ui-react';

const ChangeCard = ({ setData,setDone,done }) => {
    const { callOpenAI4, setMessages } = useContext(CopilotContext);
    const [finished, setFinished] = useState(false);
  
    useEffect(() => {
      // Start the analysis after a 3-second delay
      if(!done){
        changeAnalysis();
        setFinished(true);
      }
    
    }, []);
  
    // Get the required data from redux
    const { raw_data, lastData, core_data, selectedFilters } = useSelector(
      (state) => ({
        anchor: state.audit?.anchor,
        get_survey_questions: state.surveyquestions.survey_questions,
        selectedFilters: state.audit?.new_filters,
        raw_data: state.audit?.raw_data,
        lastData: state.audit?.filtered_last_data,
        core_data: state.audit?.core_data,
      }),
      shallowEqual
    );
  
    async function runAnalysis() {
      // Prepare the output data structure
      const outputData = {
        culture_factors: [],
        outcome_factors: [],
        tldr_culture: "",
        tldr_outcome: ""
      };
    
      /***************
       * Culture Factors
       ***************/
      const latestFactorScores = calculateAverageScoresPerFactor(filteredData);
      const lastFactorScores = calculateAverageScoresPerFactor(lastFilteredData);
    
      if (Object.keys(lastFactorScores).length === 0) {
        // If there's no "previous survey" data, we can just stop here
        setDone(true);
        return;
      }
    
      // Get the union of all factor keys (composite keys: "factor_dimension")
      const allFactorKeys = new Set([
        ...Object.keys(latestFactorScores),
        ...Object.keys(lastFactorScores)
      ]);
    
      // Determine how many culture factors we expect to process
      const totalCultureFactors = allFactorKeys.size;
      let processedCultureCount = 0;
    
      // Temporary structure for collecting factor changes
      const factorChanges = [];
    
      // Process each culture factor sequentially
      for (let key of allFactorKeys) {
        const parts = key.split("_");
        const factorId = parseInt(parts[0], 10);
        const dimensionId = parseInt(parts[1], 10);
    
        const factorName = getFactorName(questions, [factorId, dimensionId]);
        const latestScore =
          latestFactorScores[key] !== undefined
            ? latestFactorScores[key]
            : "No data";
        const lastScore =
          lastFactorScores[key] !== undefined ? lastFactorScores[key] : "No data";
    
        const latestFeedback = getFactorFeedback(
          [factorId, dimensionId],
          filteredData
        );
        const lastFeedback = getFactorFeedback(
          [factorId, dimensionId],
          lastFilteredData
        );
    
        // Skip if there’s no data from the previous survey
        if (lastScore === "No data") {
          continue;
        }
    
        // Build your prompt
        const prompt = `
          Please analyze the following data on the culture factor: **${factorName}**
          ---
          **Scores**  
          - Latest: ${Math.round(latestScore * 10 * 10) / 10}  
          - Previous: ${Math.round(lastScore * 10 * 10) / 10}
    
          **Latest Survey Feedback**  
          ${latestFeedback}
    
          **Previous Survey Feedback**  
          ${lastFeedback}
          ---
    
          Provide a **markdown-formatted** report with these sections:
    
          **Executive Summary**  
             - **1 sentence** explaining what changed and why.
    
          **Why Did ${factorName} Change?**  
             - **1–2 sentences** on the main trends and factors influencing the score shift.
    
          **Key Differences in Trends**  
             - **2–3 bullet points**, each with **1–2 sentences** comparing feedback from the two surveys.
    
          **Quotes from Feedback**  
             - **2–3 direct quotes** that illustrate the biggest shifts or themes.
    
          **Deeper Insights & Possible Root Causes**  
             - Offer **1-2 paragraphs** diving deeper into why these trends have emerged.  
             - Connect them with organizational or contextual factors that might explain the shift (e.g., new leadership, policy changes, external events).
    
          **Conclusion**  
             - **Short paragraph** wrapping up key takeaways and any final thoughts.
    
          **Tone & Format**  
          - Keep it clear, concise, and professional.  
          - Use **bold text** and headings for clarity.  
          - Aim for an **engaging, insight-focused** style that helps readers quickly grasp the main points.
          - Do not add the markdown words at the start of your output
        `;
    
        const gptResponse = await callOpenAI4(prompt);
    
        // Add this factor’s data to the output
        outputData.culture_factors.push({
          factor_id: key,
          factor_name: factorName,
          latest_score: latestScore,
          last_score: lastScore,
          latest_feedback: latestFeedback,
          last_feedback: lastFeedback,
          gpt_analysis: gptResponse
        });
    
        // Track the numeric score change
        const scoreChange =
          typeof latestScore === "number" && typeof lastScore === "number"
            ? latestScore - lastScore
            : 0;
        factorChanges.push({
          factor_id: key,
          factor_name: factorName,
          latest_score: latestScore,
          last_score: lastScore,
          score_change: scoreChange
        });
    
        // Increment our processed count
        processedCultureCount++;
    
        // Update state incrementally to reflect partial data AND progress
        setData((prev) => ({
          ...prev,
          culture_factors: [...outputData.culture_factors],
          progress: {
            // You can also merge in existing progress if you track it
            ...prev.progress,
            totalCultureFactors,
            processedCultureCount
          }
        }));
      }
    
      // Generate TLDR for culture factors
      let trendData = "";
      outputData.culture_factors.forEach((item) => {
        const scoreChangePercent =
          typeof item.latest_score === "number" && typeof item.last_score === "number"
            ? Math.round((item.latest_score - item.last_score) * 10)
            : 0;
        trendData += `${item.factor_name} analysis: score change ${scoreChangePercent}% \n\n${item.gpt_analysis}\n\n`;
      });
      trendData +=
        "Based on the analysis of the culture factors above, and the change in scores, generate a tldr of the top trends in markdown syntax. Make the tldr concise and only use 3 bullet points each with 2-4 sentences.";
      outputData.tldr_culture = await callOpenAI4(trendData);
    
      // Update the TLDR for culture factors in the state
      setData((prev) => ({ ...prev, tldr_culture: outputData.tldr_culture }));
    
      /***************
       * Outcome Factors
       ***************/
      const latestOutcomeScores = calculateAverageScoresPerOutcomeFactor(filteredData);
      const lastOutcomeScores = calculateAverageScoresPerOutcomeFactor(lastFilteredData);
    
      // Get the union of outcome IDs (as strings)
      const allOutcomeKeys = new Set([
        ...Object.keys(latestOutcomeScores),
        ...Object.keys(lastOutcomeScores)
      ]);
      const totalOutcomeFactors = allOutcomeKeys.size;
      let processedOutcomeCount = 0;
    
      const outcomeChanges = [];
    
      for (let outcomeId of allOutcomeKeys) {
        const outcomeName = getOutcomeName(outcomeId, outcome_questions);
        const latestScore =
          latestOutcomeScores[outcomeId] !== undefined
            ? latestOutcomeScores[outcomeId]
            : "No data";
        const lastScore =
          lastOutcomeScores[outcomeId] !== undefined
            ? lastOutcomeScores[outcomeId]
            : "No data";
    
        const latestFeedback = getOutcomeFeedback(filteredData, outcomeId, outcomeName);
        const lastFeedback = getOutcomeFeedback(
          lastFilteredData,
          outcomeId,
          outcomeName
        );
    
        // Skip if no latest feedback is available
        if (!latestFeedback) continue;
    
        const prompt = `
          Please analyze the following data for the outcome factor '${outcomeName}':
    
          Scores:
          - Latest Score: ${Math.round(latestScore * 10 * 10) / 10}
          - Last Score: ${
            lastScore !== "No data"
              ? Math.round(lastScore * 10 * 10) / 10
              : lastScore
          }
    
          Feedback from latest survey:
          ${latestFeedback}
    
          Feedback from last survey:
          ${lastFeedback}
    
          Based on the change in score since the last survey, and the feedback from both surveys, provide an analysis of the outcome factor ${outcomeName} with a focus on the reasons for changes in scores based on the feedback. Describe the key trends, how they have changed, and what is the likely cause of the shift.
          Answer the following questions in your analysis and use this as a header:
          ## Why did ${outcomeName} change?
          3-4 sentences describing in simple terms the key trends and reasons for changes in the factor score.
    
          ## Key differences in trends
          3-4 bullet points listing out the consistent trends from the feedback from last survey and the latest survey. Under each bullet, 2-3 sentences describing how this trend has evolved or changed.
    
          Use markdown syntax, make it looks nice.
        `;
    
        const gptResponse = await callOpenAI4(prompt);
    
        outputData.outcome_factors.push({
          outcome_id: outcomeId,
          outcome_name: outcomeName,
          latest_score: latestScore,
          last_score: lastScore,
          latest_feedback: latestFeedback,
          last_feedback: lastFeedback,
          gpt_analysis: gptResponse
        });
    
        const scoreChange =
          typeof latestScore === "number" && typeof lastScore === "number"
            ? latestScore - lastScore
            : 0;
        outcomeChanges.push({
          outcome_id: outcomeId,
          outcome_name: outcomeName,
          latest_score: latestScore,
          last_score: lastScore,
          score_change: scoreChange
        });
    
        processedOutcomeCount++;
    
        // Incrementally update state with partial outcome data + progress
        setData((prev) => ({
          ...prev,
          outcome_factors: [...outputData.outcome_factors],
          progress: {
            ...prev.progress,
            totalOutcomeFactors,
            processedOutcomeCount
          }
        }));
      }
    
      // Generate TLDR for outcome factors
      let trendDataOutcome = "";
      outputData.outcome_factors.forEach((item) => {
        const scoreChangeValue =
          typeof item.latest_score === "number" && typeof item.last_score === "number"
            ? item.latest_score - item.last_score
            : "No prior data";
        trendDataOutcome += `${item.outcome_name} analysis: score change ${scoreChangeValue}% \n\n${item.gpt_analysis}\n\n`;
      });
      trendDataOutcome +=
        "Based on the analysis of the outcome factors above, and the change in scores, generate a tldr of the top trends in markdown syntax. Make the tldr concise and only use 3 bullet points each with 3-4 sentences.";
      outputData.tldr_outcome = await callOpenAI4(trendDataOutcome);
    
      // Final update for outcome TLDR + entire dataset
      setData((prev) => ({
        ...prev,
        tldr_outcome: outputData.tldr_outcome
      }));
    
      // Mark done once all is complete
      setDone(true);
      return outputData;
    }
    
  
    // Prepare the data needed for analysis
    const filteredData = filter_data(raw_data[0].responses, selectedFilters);
    const lastFilteredData = filter_data(lastData, selectedFilters);
    const questions = core_data.questions;
    const outcome_questions = core_data.outcomeQuestions;
  
    const changeAnalysis = () => {
      (async () => {
        try {
          await runAnalysis();
        } catch (error) {
          console.error("Error during analysis:", error);
        }
      })();
    };
  
    return (
      <div/>
    );
  };
  
  export default ChangeCard;
  




const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Card = styled.div`
    padding: 20px;
    border-radius: 10px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
        opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;

 opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`

/************************************
 * CALCULATION FUNCTIONS (HELPERS)
 ************************************/

/**
 * Calculate the average scores per culture factor.
 * Each entry in data is expected to have a “questions” array.
 * We use a composite key "factor_dimension" (e.g. "0_1") to track each factor.
 */
function calculateAverageScoresPerFactor(data) {
    const factorScores = {};
    data.forEach(entry => {
      const questions = entry.questions || [];
      questions.forEach(question => {
        const factor = question.factor;
        const dimension = question.id; // assuming "id" represents the dimension id
        const response = question.response;
        if (factor != null && dimension != null && response != null) {
          const key = `${factor}_${dimension}`;
          if (factorScores[key]) {
            factorScores[key].sum += response;
            factorScores[key].count += 1;
          } else {
            factorScores[key] = { sum: response, count: 1, factor, dimension };
          }
        }
      });
    });
    // Calculate averages for each factor key
    const averages = {};
    Object.keys(factorScores).forEach(key => {
      averages[key] = factorScores[key].sum / factorScores[key].count;
    });
    return averages;
  }
  
  /**
   * Calculate the average scores per outcome factor.
   * Each entry is expected to have a “modular_response.outcome_question.responses” array.
   */
  function calculateAverageScoresPerOutcomeFactor(data) {
    const outcomeScores = {};
    data.forEach(entry => {
      const modularResponse = entry.modular_response || {};
      const outcomeQuestion = modularResponse.outcome_question || {};
      const responses = outcomeQuestion.responses || [];
      responses.forEach(question => {
        const outcomeId = question.q != null ? String(question.q) : null;
        const response = question.response;
        if (outcomeId != null && response != null) {
          if (outcomeScores[outcomeId]) {
            outcomeScores[outcomeId].sum += response;
            outcomeScores[outcomeId].count += 1;
          } else {
            outcomeScores[outcomeId] = { sum: response, count: 1 };
          }
        }
      });
    });
    // Calculate averages for each outcome
    const averages = {};
    Object.keys(outcomeScores).forEach(outcomeId => {
      averages[outcomeId] = outcomeScores[outcomeId].sum / outcomeScores[outcomeId].count;
    });
    return averages;
  }
  

  function filterFeedback(responses, factor, dimension) {
    if (!Array.isArray(responses)) return [];
    let filtered = [];
    
    responses.forEach(resp => {
      // Try both possible keys for the feedback array
      const feedbacks = resp.feedbacks || resp.feedback || [];
      feedbacks.forEach(fb => {
        if (fb.factor === factor && fb.dimension === dimension) {
          filtered.push(fb);
        }
      });
    });
    
    return filtered;
  }
  /**
   * Get formatted feedback for a given culture factor.
   * Searches each response’s "feedbacks" array for matching factor & dimension.
   */
  function getFactorFeedback(factorTuple, responses, options = {}) {
    if (!factorTuple) return "";
    const [factor, dimension] = factorTuple;
    
    // Filter the responses
    const feedbackList = filterFeedback(responses, factor, dimension);
    if (!feedbackList.length) return "";
    
    // Depending on options, choose a formatting strategy. For now, we use one format.
    return formatFeedback(feedbackList, options);
  }
  
  function formatFeedback(feedbackList, options = {}) {
    if (!feedbackList.length) return "";
    
    const { headerName } = options;
    let formatted = headerName
      ? `### Feedback from ${headerName} responses:\n\n`
      : "### Feedback from responses:\n\n";
    
    feedbackList.forEach(item => {
      // If prompt_question exists, handle it differently
      if (item.prompt_question) {
        formatted += "Follow up: " + item.prompt_question + "\n";
        formatted += "Response: " + (item.feedback || "") + "\n";
        formatted += "\n";
        return;
      }
      
      if (item.feedback) {
        formatted += "Question asked: " + (item.question || "") + "\n";
        formatted += "Response: " + item.feedback + "\n";
      }
      
      if (item.follow_up && item.follow_up_question) {
        formatted += "Follow up: " + item.follow_up_question + "\n";
        formatted += "Response: " + item.follow_up + "\n";
      }
      
      formatted += "\n";
    });
    
    formatted += "### END OF FEEDBACK \n\n";
    return formatted;
  }
  /**
   * Get formatted outcome feedback.
   * For each response, check modular_response.feedback_builder.responses for matches.
   */
  function getOutcomeFeedback(responses, outcomeId, outcomeName) {
    let feedbackList = [];
    responses.forEach(resp => {
      const modularResponse = resp.modular_response;
      if (!modularResponse || !modularResponse.feedback_builder) return;
      const fbResponses = modularResponse.feedback_builder.responses || [];
      fbResponses.forEach(feed => {
        // Compare using string conversion to be safe
        if (feed.q != null && String(feed.q) === String(outcomeId) && feed.response) {
          feedbackList.push(feed.response);
        }
      });
    });
    if (feedbackList.length === 0) return null;
    let formattedFeedback = `The following is feedback collected in relation to ${outcomeName}:\n\n`;
    feedbackList.forEach(item => {
      formattedFeedback += item + "\n";
    });
    formattedFeedback += "## END OF FEEDBACK \n\n";
    return formattedFeedback;
  }
  
  /**
   * Given a questions structure (with dimensions and factors) and a factor tuple,
   * return the factor name. (This mimics the Python get_factor_name function.)
   *
   * We assume that the questions structure has a "dimensions" array.
   * Note: The Python version increments the id by 1; adjust if needed.
   */
  function getFactorName(questionsStructure, factorTuple) {
    if (!factorTuple) return null;
    const [factorId, dimensionId] = factorTuple;
    const dimensions = questionsStructure.dimensions || [];
    // Find the dimension whose id equals dimensionId+1 (adjust if necessary)
    const dimension = dimensions.find(dim => dim.id === dimensionId + 1);
    if (!dimension) return null;
    const factor = dimension.factors.find(fact => fact.id === factorId + 1);
    if (!factor) return null;
    return factor.title;
  }
  
  /**
   * Get the outcome name from an array of outcome questions.
   * Assumes each outcome has an id and a title (or name).
   */
  function getOutcomeName(outcomeId, outcomeQuestions) {
    const outcome = outcomeQuestions.find(o => String(o.id) === String(outcomeId));
    return outcome ? (outcome.title || outcome.name) : `Outcome ${outcomeId}`;
  }