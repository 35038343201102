import React, { useState } from 'react';
import styled from 'styled-components';

import IMG from 'assets/images/UI/white-paper-small.jpg';

// ----- Styled Components -----
const Container = styled.div`
  font-family: sans-serif;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
`;

const HeaderBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Tab = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  margin-right: 16px;
  padding: 8px 0;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s;

  ${(props) =>
    props.active &&
    `
    border-bottom: 2px solid #333;
    font-weight: 600;
  `}

  &:hover {
    color: #0077FF;
  }
`;

const CreateSurveyButton = styled.button`
  margin-left: auto;
  padding: 8px 16px;
  background-color: #0077FF;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    background-color: #005bbf;
  }
`;

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`;

const TableHead = styled.th`
  text-align: left;
  background-color: #f9f9f9;
  padding: 12px;
  font-weight: 600;
  border-bottom: 2px solid #eee;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #fafafa;
  }
`;

const TableData = styled.td`
  padding: 12px;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
`;

const SurveyTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SurveyImage = styled.img`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 12px;
  border: 1px solid #ddd;
`;

// Button group container so buttons line up nicely
const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const SendSurveyButton = styled.button`
  padding: 8px 12px;
  background-color: #00aa55;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #008d46;
  }
`;

const MoreInfoButton = styled.button`
  padding: 8px 12px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #e2e2e2;
  }
`;

// ----- Sidebar Styles -----
const SidebarOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${(props) => (props.open ? '100vw' : '0')};
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  pointer-events: ${(props) => (props.open ? 'all' : 'none')};
  z-index: 1000;
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const SidebarTitle = styled.h3`
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
`;

const SidebarContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SidebarFooter = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DownloadLink = styled.a`
  text-decoration: none;
  color: #0077FF;
  font-weight: 600;
  margin-top: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

// ----- Mock Data -----
const mockSurveys = [
  {
    id: 1,
    title: 'Employee Engagement Survey',
    image: 'https://via.placeholder.com/60?text=EES',
    status: 'Draft',
    author: 'Jane Doe',
    description: 'This survey aims to measure overall employee engagement.',
  },
  {
    id: 2,
    title: 'Customer Satisfaction Survey',
    image: 'https://via.placeholder.com/60?text=CSS',
    status: 'Published',
    author: 'John Smith',
    description: 'Used to measure customer satisfaction across various products.',
  },
  {
    id: 3,
    title: 'Product Feedback Survey',
    image: 'https://via.placeholder.com/60?text=PFS',
    status: 'Published',
    author: 'Team Lead',
    description: 'Collects direct customer feedback on new product launches.',
  },
];

// ----- React Component -----
export default function SurveyTable({setStep}) {
  const [activeTab, setActiveTab] = useState('All');
  // Store which survey is selected for more info
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const openSidebar = (survey) => {
    setSelectedSurvey(survey);
  };

  const closeSidebar = () => {
    setSelectedSurvey(null);
  };

  // Filter logic if you want to show different items per tab
  const filteredSurveys = mockSurveys.filter(() => {
    if (activeTab === 'All') return true;
    // Insert other filtering conditions if needed.
    return true;
  });

  return (
    <>
      <Container>
        <HeaderBar>
          <Tab active={activeTab === 'All'} onClick={() => setActiveTab('All')}>
            All
          </Tab>
          <Tab
            active={activeTab === 'Shared surveys'}
            onClick={() => setActiveTab('Shared surveys')}
          >
            Shared surveys
          </Tab>
          <Tab
            active={activeTab === 'My surveys'}
            onClick={() => setActiveTab('My surveys')}
          >
            My surveys
          </Tab>
          <Tab
            active={activeTab === 'innerlogic'}
            onClick={() => setActiveTab('innerlogic')}
          >
            innerlogic
          </Tab>
          <CreateSurveyButton onClick={()=>setStep(1)}>+ create survey</CreateSurveyButton>
        </HeaderBar>

        <TableWrapper>
          <thead>
            <tr>
              <TableHead>Survey</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Action</TableHead>
            </tr>
          </thead>
          <tbody>
            {filteredSurveys.map((survey) => (
              <TableRow key={survey.id}>
                <TableData>
                  <SurveyTitleContainer>
                    {/* Use your own or the included image */}
                    <SurveyImage src={IMG} alt={survey.title} />
                    {survey.title}
                  </SurveyTitleContainer>
                </TableData>
                <TableData>{survey.status}</TableData>
                <TableData>
                  <ButtonGroup>
                    <SendSurveyButton>send survey</SendSurveyButton>
                    <MoreInfoButton onClick={() => openSidebar(survey)}>
                      More Info
                    </MoreInfoButton>
                  </ButtonGroup>
                </TableData>
              </TableRow>
            ))}
          </tbody>
        </TableWrapper>
      </Container>

      {/* Sidebar & Overlay */}
      <SidebarOverlay open={!!selectedSurvey} onClick={closeSidebar} />
      <SidebarContainer open={!!selectedSurvey}>
        {selectedSurvey && (
          <>
            <SidebarHeader>
              <SidebarTitle>{selectedSurvey.title}</SidebarTitle>
              <CloseButton onClick={closeSidebar}>×</CloseButton>
            </SidebarHeader>
            <SidebarContent>
              <p><strong>Author:</strong> {selectedSurvey.author}</p>
              <p>{selectedSurvey.description}</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                eget orci vitae turpis elementum lobortis. Duis ultricies,
                nulla quis lobortis dapibus, risus elit tristique ante,
                quis mattis sapien orci ac turpis.
              </p>
            </SidebarContent>
            <SidebarFooter>
              <span>Download Whitepaper:</span>
              <DownloadLink href="#" download>
                Example_Whitepaper.pdf
              </DownloadLink>
            </SidebarFooter>
          </>
        )}
      </SidebarContainer>
    </>
  );
}
