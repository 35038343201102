import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import html2canvas from 'html2canvas';
// Removed jsPDF import since we’re not generating a PDF
// import { jsPDF } from 'jspdf';

import { CopilotContext, CopilotProvider } from '../CopilotContext';
import Risk from '../Functions/Risk';
import { formatElements } from '../Functions/parseText';
import AICard from '../Components/AICard';
import Suggestions from '../Components/Suggestions';
import FollowUpCard from '../Components/FollowUpCard';
import { RunThematic } from '../Functions/ThematicsAnalysis';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { SET_NEW_FILTERS } from "constants/actions";
import RiskCard from '../Components/RiskCard';
import IntroSection from '../Components/IntroSection';

function RiskHomeComponent({ goHome, initialSession, onSaveSession }) {
  const scrollRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const dispatch = useDispatch();

  // Create a ref array for each bubble (for image export)
  const bubbleRefs = useRef([]);

  // Pull from CopilotContext
  const {
    messages,
    setMessages,
    phase,
    clearMessages,
    setPromptText,
    promptText,
    executeProcess
  } = useContext(CopilotContext);

  const [prompt, setPrompt] = useState('');
  const [started, setStarted] = useState(false);
  const [suggestionHistory, setSuggestionHistory] = useState([]);
  const [selectedFactors, setSelectedFactors] = useState({ type: 'Culture' });
  const [followUp, setFollowUp] = useState(false);
  const selectedFilters = useSelector((state) => state.audit?.new_filters, shallowEqual);

  useEffect(() => {
    return () => {
      dispatch({ type: SET_NEW_FILTERS, payload: [] });
    };
  }, [dispatch]);

  /**
   * 1) If we have an initialSession with messages, load them and set started = true
   */
  useEffect(() => {
    if (initialSession) {
        console.log(initialSession)
      // restore messages
      if (initialSession.messages) {
        setMessages(initialSession.messages);
      }
      // restore selectedFactors
      if (initialSession.selectedFactors) {
        setSelectedFactors(initialSession.selectedFactors);
      }
      // restore suggestionHistory
      if (initialSession.suggestionHistory) {
        setSuggestionHistory(initialSession.suggestionHistory);
      }
      if (initialSession.filters) {
        dispatch({ type: SET_NEW_FILTERS, payload: initialSession.filters });
      }
    }
  }, [initialSession, setMessages, dispatch]);

  /**
   * 2) If user sets a new prompt (and we have NO existing messages), do the "intro start"
   *    Only do this if messages is empty (i.e. brand-new session).
   */
  useEffect(() => {
    if (prompt && !started && messages.length === 0) {
      setStarted(true);
      setMessages(prev => [
        ...prev,
        { role: 'function', content: 'Running Risk Assessment', type: 'Risk Assessment' }
      ]);
    }
  }, [prompt, started, messages, setMessages]);

  /**
   * 3) If user triggers a follow-up, insert that once
   */
  useEffect(() => {
    if (followUp) {
      setMessages(prev => [
        ...prev,
        { role: 'follow_up', content: 'Running thematic analysis on feedback' }
      ]);
    }
  }, [followUp, setMessages]);

  /**
   * 4) Scroll to the bottom each time messages change
   */
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }

   
  }, [messages]);

  /**
   * Called from <Risk> whenever user sets a new data/prompt
   */
  const registerHistory = (data, promptString, analysisBreakdown, low_score) => {
    setPrompt(promptString); // store the user's prompt
    setSuggestionHistory(prev => [...prev, { data, analysisBreakdown, low_score }]);
  };

  const start = () => {

    const updatedMessage = [...messages,{ role: 'assistant', content: '' }]

    setMessages((prev) => [
      ...prev,
      { role: 'assistant', content: '' }
    ], []);
    onSaveSession?.({
        type: 'risk',
        messages: updatedMessage,
        selectedFactors,
        suggestionHistory,
        filters: selectedFilters
      });

  }

  /**
   * "Back" button resets the entire local state & context
   */
  const goBack = () => {
    clearMessages();
    setSelectedFactors({ type: 'Culture' });
    setPromptText('');
    setPrompt('');
    setStarted(false);
  };

  /**
   * Capture a bubble’s content as an image.
   * Uses html2canvas to generate an image and then triggers a download.
   */
  const captureBubbleImage = async (index, messageRole) => {
    const bubbleElement = bubbleRefs.current[index];
    if (!bubbleElement) return;
    
    try {
      const canvas = await html2canvas(bubbleElement, {
        scale: 2, // Increase scale for better resolution
        backgroundColor: null,
      });

      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = `risk-report-${messageRole}-${index}.png`;
      link.click();
    } catch (error) {
      console.error('Error generating image', error);
    }
  };

  // If the user hasn't started and there are no messages, show the "intro" <Risk> screen
  if (!started && messages.length === 0) {
    return (
      <Container>
        <Risk
          intro={true}
          selectedFactors={selectedFactors}
          setSelectedFactors={setSelectedFactors}
          onClick={({ data, prompt, analysisBreakdown, low_score }) =>
            registerHistory(data, prompt, analysisBreakdown, low_score)
          }
        />
      </Container>
    );
  }

  // Otherwise, show the conversation (including bubbles with a download image icon)
  return (
    <Container ref={scrollRef}>
      <Title>Risk Assessment Workflow</Title>

      <Content>
        <MessageContainer>
          {messages.map((msg, i) => {
            if (msg.role === 'function') {
              // Check if there's ANY assistant message after this index:
              console.log(messages)
              const hasAssistantAfter = messages
                .slice(i + 1)
                .some((m) => m.role === 'assistant');

              return (
                <AICard
                  key={i}
                  alreadyRun={hasAssistantAfter}
                  start={() => start()}
                />
              );
            }
            if (msg.role === 'follow_up') {
              return (
                <FollowUpCard
                  key={i}
                  start={() => {
                    RunThematic(
                      (args, callbacks) => executeProcess(args, callbacks),
                      followUp,
                      {
                        onDone: (finalMessages) => {
                          onSaveSession?.({
                            type: 'risk',
                            messages: finalMessages,
                            selectedFactors,
                            suggestionHistory,
                          });
                        }
                      }
                    );
                  }}
                />
              );
            }

            // For regular messages rendered in a Bubble, also include a download image icon.
            const retrievedSuggestion = suggestionHistory[suggestionHistory.length - 1];
            const currentlyRisk = messages[i - 1]?.type === 'Risk Assessment';
            return (
              <Bubble
                key={i}
                role={msg.role}
                // Attach a ref so we can capture this bubble’s DOM element
                ref={(el) => (bubbleRefs.current[i] = el)}
                // Ensure position relative so our icon can be positioned absolutely within
                style={{ position: 'relative' }}
              >
                {currentlyRisk && <IntroSection data={formatElements(msg.content)} />}
                {!currentlyRisk && formatElements(msg.content)}
                {currentlyRisk && (
                  <RiskCard data={retrievedSuggestion} type={selectedFactors} />
                )}
                {phase === "idle" &&
                  i + 1 === messages.length &&
                  retrievedSuggestion && (
                    <Suggestions
                      data={retrievedSuggestion}
                      selectFollowUp={(d) => setFollowUp({ data: retrievedSuggestion, selection: d })}
                    />
                )}

                {/* Download image icon in the top right corner */}
                <DownloadPdfIcon onClick={() => captureBubbleImage(i, msg.role)}>
                  <Icon name="download" />
                </DownloadPdfIcon>
              </Bubble>
            );
          })}
          <div ref={endOfMessagesRef} />
        </MessageContainer>
      </Content>
    </Container>
  );
}

export default function RiskHome(props) {
  return (
    <CopilotProvider>
      <RiskHomeComponent {...props} />
    </CopilotProvider>
  );
}

// -- Styled Components below --

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to   { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  font-family: 'Raleway', sans-serif;
  padding: 20px;
  padding-top: 0px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #2A3039;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

const MessageContainer = styled.div`
  width: 100%;
  max-width: 900px;
  padding-bottom: 150px;
`;

const Bubble = styled.div`
  background-color: ${(props) => (props.role === 'assistant' ? 'white' : '#dcecff')};
  padding: 70px 60px;
  margin-bottom: 8px;
  border-radius: 6px;
  max-width: 100%;
  animation: ${fadeIn} 0.5s forwards;
  border: 1px solid lightgrey;
`;

// Styled component for the download image icon (positioned in the bubble)
const DownloadPdfIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #007bff;
  z-index: 10;
`;
