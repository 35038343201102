import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import IMG from "assets/images/stock/office.png"

const IntroSection = ({data}) =>{

    return (

        <Container>
           <TopTitle>Risk Analytics Report</TopTitle>

            <Content>
                <Text>
                    <Title>Introduction</Title>
                    Company culture plays a crucial role in organizational success, but understanding its risks requires a data-driven approach. This report assesses culture-related risks using statistical modeling and benchmarking across multiple factors. By analyzing all risk categories, we identify the most influential drivers of culture risk, highlight high-risk areas, and uncover actionable insights to mitigate potential challenges.
                </Text>

                <ImgContent>
                <Image src={IMG} />
                </ImgContent>
                
            </Content>
        </Container>
    )
}

export default IntroSection;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0);}
`;

const TopTitle = styled.div`
    color:#2D70E2;
    font-size:30px;
    letter-spacing:-4%;
    font-weight:400;
    font-family:'Outfit', sans-serif;
    margin-bottom:40px;
`

const Container = styled.div`
    width:100%;
    margin-bottom:10px;

    opacity: 0;
    animation: ${fadeIn} 0.5s forwards;
`

const Title = styled.div`
    color:#2A3039;
    font-size:20px;
    font-weight:bold;
    font-family:'Outfit', sans-serif;
    margin-bottom:20px;
`

const Content = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    min-height:300px;
`

const Text = styled.div`
    width:50%;
    color:#2A3039;
    line-height:1.5;

`

const ImgContent = styled.div`
    width:40%;
    display:flex;
    justify-content:flex-end;
    align-items:flex-start;
`

const Image = styled.img`
    width: 100%; /* Makes sure the image scales within its container */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the area */
`