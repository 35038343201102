// App.js (or wherever you orchestrate contexts)

import React from 'react';


import { CopilotProvider } from './CopilotContext'; 
import { ActionPlanProvider } from './ActionPlanningContext.js';
import ActionPlanningAgent from './ActionPlanningAgent';

function App() {
  return (
      <CopilotProvider>
        <ActionPlanProvider>
          <ActionPlanningAgent />
        </ActionPlanProvider>
      </CopilotProvider>
  );
}

export default App;
