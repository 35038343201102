import React, { createContext, useState, useEffect } from 'react';
import styled,{keyframes} from 'styled-components'
import { Loader } from 'semantic-ui-react';

const AICard = ({start,alreadyRun}) =>{

    const [finished,setFinished] = useState(false);

    useEffect(() => {
        setTimeout(() => {

            if(!alreadyRun){
                start()
            setFinished(true);
            } 
        }
        , 3000);
    }, [])


    return (
        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%',marginTop:20}}>
            <Card>
                {!finished ? (
                    <>
                    <Title>Running risk analysis</Title>
                    <Loader active inline size="mini" style={{marginLeft:20}}/>
                    </>
                ) :
                <Title>Completed Assessment</Title>
                }
            
            </Card>
        </div>
    )
}

export default AICard;



const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Card = styled.div`
    padding: 20px;
    border-radius: 10px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
        opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;

 opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`