import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Warning from 'assets/images/UI/warning.svg';

const RiskCard = ({ data,type }) => {

    return (
        <Container>
            <Title>Score Risk & Summary</Title>
            <Description>
            We've looked at how likely different places and groups are to have low {type.type} scores. Below, you'll see the top <strong>3 biggest risk areas</strong> where scores are low.
            </Description>

        <CardContent>
            {data.data.slice(0, 3).map((d, i) => {
                return (
                    <Card>
                        <IconArea>
                            <Icon src={Warning} />
                        </IconArea>
                        <T2>{d.groupValue} {d.categoryColumn}</T2>
                        <D2>
                            The {d.groupValue} {d.categoryColumn} group is <strong>{d.relativeRisk.toFixed(1)}</strong> times more likely to score low on {type.type} than average.
                        </D2>

                        <D2>
                            {d.likelihood.toFixed(2)*100}% of the people there scored a {data.low_score} or lower on {type.type}.
                        </D2>
                    </Card>
                )
            }
            )}
        </CardContent>
        </Container>
    )
}

export default RiskCard;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0);}
`;

const Title = styled.div`
    color:#2A3039;
    font-size:20px;
    font-weight:bold;
    font-family:'Outfit', sans-serif;
    margin-bottom:20px;
`

const Description = styled.div`
    font-weight:400;
    font-family:'Raleway', sans-serif;
`

const Container = styled.div`
    opacity: 0;
    animation: ${fadeIn} 0.5s forwards;
`

const Card = styled.div`
    background-color: #FEF3F4;
    padding: 32px;
    width:calc(33% - 5px);
    border-radius:16px;
    font-family:'Raleway', sans-serif;

`

const T2 = styled.div`
    color:#F15062;
    font-size: 16px;
    font-weight: 600;
    margin-bottom:20px;

`

const D2 = styled.div`
    color:#2A3039;
    font-size:14px;
    font-weight:500;
    margin-bottom:20px;
`

const CardContent = styled.div`
    display:flex;
    justify-content: space-between;
    margin-top:20px;
    width:100%;
    flex-wrap: wrap;
    gap: 5px;
`

const IconArea = styled.div`
    background-color: #FCDCE0;
    margin-bottom:20px;
    width:30px;
    height:30px;
    border-radius:8px;
    display:flex;
`

const Icon = styled.img`
    width:15px;
    height:15px;
    margin:auto;
`