import React, { createContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {Dropdown} from 'semantic-ui-react';
import { useSelector, shallowEqual } from 'react-redux';
import {mainAnalysis} from './risk_calculations';
import {multiple_regression2} from "app/audit/calculations";


function countUniqueDimensionFactorCombos(data) {
  if (!data || !data.questions) {
      console.error("Invalid data format");
      return 0;
  }



  const uniqueCombos = new Set();

  data.questions.forEach(question => {
      if (question.id !== undefined && question.factor !== undefined) {
          const combo = `${question.id || 0}-${question.factor}`;
          uniqueCombos.add(combo);
      }
  });

  return uniqueCombos.size;
}


const Risk = ({onClick,intro,selectedFactors,setSelectedFactors}) =>{
    
    const [loading, setLoading] = useState(false);
    const [factorDropdown, setFactorDropdown] = useState(null);
    const [prompt, setPrompt] = useState('');
    

    const core_data = useSelector((state) => state.audit?.core_data,shallowEqual);
    const raw_data = useSelector((state) => state.audit?.raw_data,shallowEqual);
    const getOrganizations = useSelector((state) => state.organizations);
    const getAuth = useSelector((state) => state.auth);
    const getSurveyQuestions = useSelector((state) => state.surveyquestions);

    useEffect(() => {
        const factorDrop = []
        const orgId = getAuth?.organization_id || getOrganizations?.organization?.id;
        const organizationData = getOrganizations?.[orgId];
        if (organizationData) {
            const sortOrder = organizationData?.styling?.survey_sequence?.find(
              (item) => item.value === "outcome_question"
            )?.question?.sort_order;
      
            const outcomeQ = getSurveyQuestions?.outcome_questions?.find(
              (item) => item.sort_order === sortOrder
            );

            // Add outcome factors
          outcomeQ?.questions.forEach((q) => {
            factorDrop.push({
              text: q.name,
              value: { ...q, type: "outcome" }, // Keep as a JS object
            });
          });
        }
      

        setFactorDropdown(factorDrop);
        setSelectedFactors(factorDrop[0]?.value);
        
    }
    , [core_data])

    useEffect(() => {
        if(!selectedFactors){
            return;
        }
            // Then you can do:
            const totalFactors = core_data?.questions?.dimensions.reduce(
              (acc, dimension) => acc + dimension.factors.length,
              0
            );

            const modified_data_format = raw_data[0].responses.filter(f=>countUniqueDimensionFactorCombos(f) === totalFactors);
            const regressionResults = multiple_regression2(
              modified_data_format,
              parseInt(selectedFactors?.id), // or whatever ID identifies your outcome variable
              core_data?.questions
            );

            const organized_feedback = []
             raw_data[0].responses.map((response) => {
              organized_feedback.push(...response.feedback);
              if(response.feedback_builder?.responses){
                organized_feedback.push(...response.feedback_builder?.responses);
              }
            });


             // Pull out the key parts you want to summarize
            const {
              correlation,
              model,
              outlierInfo,
            } = regressionResults;

            const { numberOfOutliers } = outlierInfo;


            // Sort factors by their absolute importance, descending
            const sortedFactors = Object.entries(model)
            .map(([key, val]) => ({
              name: key,
              importance: val.importance,
              coefficient: val.coefficient,
              average_score: val.average_score,
              impact: (val.coefficient * 0.1 + val.SE * 1.96 * 0.1 * val.coefficient)*100,
              index: val.index,
            }))
            .sort((a,b)=>b.impact-a.impact);


              // Grab top 3 most “important” factors (or fewer if you want)
            const topFactors = sortedFactors.slice(0, 3);

            const analysisOutput = `
            We analyzed the ${selectedFactors.name} outcome using multiple regression and found:
            - **Correlation:** ${correlation.toFixed(2)}
            - **Number of Outliers:** ${numberOfOutliers}
            - **Top 3 Predictive Factors (by potential impact):**
              1. ${topFactors[0]?.name || 'N/A'} 
                 (Potential Impact: ${topFactors[0]?.impact.toFixed(1)  || 'N/A'})
              2. ${topFactors[1]?.name || 'N/A'} 
                 (Potential Impact: ${topFactors[1]?.impact.toFixed(1)  || 'N/A'})
              3. ${topFactors[2]?.name || 'N/A'} 
                 (Potential Impact: ${topFactors[2]?.impact.toFixed(1) || 'N/A'})
            
            In short, we see a correlation of ${correlation.toFixed(
                2
              )}, ${numberOfOutliers} data points qualified as outliers,
            and the most influential factors appear to be: 
            "${topFactors.map((f) => f.name).join('", "')}."
            
            Please provide a short summary that explains these findings in simple terms for a non-technical audience.
            Focus on making it a report that reads easily, and helps a person who is generating this report to understand exactly what this means.
            explain the significance of the impact (%) on the outcome in the context of how this factor impacts the outcome. 
            We calculated the impact by using the coefficients from the regression and simulating a 10% increase in that factor.
            Use markdown syntax to make the report look nice and readable.
              `.trim();


                // 5) Set the prompt (and optionally store topFactors or other data as needed)
              setPrompt({
                data: topFactors,
                prompt: analysisOutput,
                organized_feedback,
              });
    },[selectedFactors])

   
    


  
    if(intro){
        return (
            <Container>
                <Content>
                    <Powered>
                    <Icon>{aiIcon("#2D70E2")} </Icon>Powered by generative AI and culture
                    experts
                    </Powered>
                    <Title2>
                    ROI <Blue>Impact</Blue>
                    </Title2>
                    <Description>
                    Welcome to the the ROI Impact analysis. This tool will help you identify the key factors that are driving the risk in your organization.
                    </Description>


                    <Description2>Select data to build an ROI model</Description2>
                    <Dropdown 
                    fluid
                    selection
                    placeholder='Select Factor' 
                    value={selectedFactors}              
                    options={factorDropdown} 
                    onChange={(e, { value }) => setSelectedFactors(value)} />

                    <Button onClick={() => onClick(prompt)} disabled={false}>Run ROI Assessment</Button>

                </Content>
        </Container>
        )
    }


    return (
        <div>
            <Title>ROI Model Settings</Title>
            <p>Please select a factor to generate ROI analysis</p>

            <Dropdown 
                fluid
                selection
                placeholder='Select Factor' 
                value={selectedFactors}              
                options={factorDropdown} 
                onChange={(e, { value }) => setSelectedFactors(value)} />

            <Button onClick={()=>onClick(prompt)}>Run ROI Analysis</Button>
        </div>
    )
}

export default Risk;


const Title = styled.div`
    font-size: 14px;
    color:#2A3039;
    font-weight:600;
    width: 250px;
    max-width:80%;

`



const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  align-items: center;
  justify-content: center;

  height: 90vh;
`;

const Content = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
`;

const Title2 = styled.div`
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1.2;
`;


const Blue = styled.span`
  color: #2d70e2;
  font-weight: 800;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.4;
  max-width: 600px;
  color: #666;
  text-align: center;
  line-height: 1.6;
  margin-bottom:30px;
`;

const Description2 = styled.div`
  font-size: 16px;
  line-height: 1.4;
  color: #2A3039;
  font-weight: 600;
  line-height: 1.6;
  width:100%;
  margin-bottom:10px;
`;

const Powered = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 5px 30px;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: center;
`;


const Button = styled.button`
  padding: 10px 20px;
  background-color: #2d70e2;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  border: none;
  margin-top:40px;
  font-family: "Raleway";

  &:hover {
    background-color: #1c4db8;
  }

  &:disabled {
    background-color: #DFDFDF; /* Lighter color for disabled state */
    cursor: not-allowed;
    opacity: 0.6; /* Reduce opacity to visually indicate disabled state */
    color:#666D79;
  }
`;

const Icon = styled.span`
  margin-right: 6px;
  margin-bottom: -4px;
`;

function aiIcon(fill) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3111 14.0751C15.1214 14.0751 14.9617 14.0122 14.8319 13.8863C14.7021 13.7605 14.6372 13.6056 14.6372 13.4217V7.19323C14.6372 7.00933 14.7021 6.85447 14.8319 6.72864C14.9617 6.59313 15.1214 6.52538 15.3111 6.52538C15.5108 6.52538 15.6755 6.58829 15.8053 6.71412C15.9351 6.83995 16 6.99965 16 7.19323V13.4217C16 13.6056 15.9301 13.7605 15.7903 13.8863C15.6605 14.0122 15.5008 14.0751 15.3111 14.0751Z"
        fill={fill}
      />
      <path
        d="M14.8265 5.03815C14.9526 5.16048 15.1041 5.22165 15.2807 5.22165H15.3564C15.5331 5.22165 15.6845 5.16048 15.8107 5.03815C15.9369 4.91581 16 4.76901 16 4.59774V4.54881C16 4.37754 15.9369 4.23074 15.8107 4.10841C15.6845 3.98607 15.5331 3.9249 15.3564 3.9249H15.2807C15.1041 3.9249 14.9526 3.98607 14.8265 4.10841C14.7003 4.23074 14.6372 4.37754 14.6372 4.54881V4.59774C14.6372 4.76901 14.7003 4.91581 14.8265 5.03815Z"
        fill={fill}
      />
      <path
        d="M0.688892 3.92805C0.878587 3.92805 1.03833 3.99092 1.16812 4.11666C1.29791 4.2424 1.36281 4.39715 1.36281 4.58093V10.805C1.36281 10.9888 1.29791 11.1435 1.16812 11.2693C1.03833 11.4047 0.878587 11.4724 0.688892 11.4724C0.489213 11.4724 0.324477 11.4095 0.194685 11.2838C0.0648939 11.1581 0 10.9985 0 10.805V4.58093C0 4.39715 0.0698868 4.2424 0.209662 4.11666C0.339454 3.99092 0.499197 3.92805 0.688892 3.92805Z"
        fill={fill}
      />
      <path
        d="M1.17355 12.9594C1.04736 12.8372 0.895935 12.7761 0.719272 12.7761H0.643561C0.466899 12.7761 0.315473 12.8372 0.189285 12.9594C0.0630978 13.0817 4.02553e-06 13.2284 4.02553e-06 13.3995V13.4484C4.02553e-06 13.6196 0.0630978 13.7663 0.189285 13.8885C0.315473 14.0108 0.466899 14.0719 0.643561 14.0719H0.719272C0.895935 14.0719 1.04736 14.0108 1.17355 13.8885C1.29974 13.7663 1.36283 13.6196 1.36283 13.4484V13.3995C1.36283 13.2284 1.29974 13.0817 1.17355 12.9594Z"
        fill={fill}
      />
      <path
        d="M10.9786 1.9657C10.9786 1.60086 11.2836 1.3051 11.66 1.3051C12.0363 1.3051 12.3414 1.60086 12.3414 1.9657V16.0343C12.3414 16.3991 12.0363 16.6949 11.66 16.6949C11.2836 16.6949 10.9786 16.3991 10.9786 16.0343V1.9657Z"
        fill={fill}
      />
      <path
        d="M3.66131 1.9657C3.66131 1.60086 3.96638 1.3051 4.34271 1.3051C4.71904 1.3051 5.02412 1.60086 5.02412 1.9657V16.0343C5.02412 16.3991 4.71904 16.6949 4.34271 16.6949C3.96638 16.6949 3.66131 16.3991 3.66131 16.0343V1.9657Z"
        fill={fill}
      />
      <path
        d="M7.31992 0.660599C7.31992 0.29576 7.625 0 8.00133 0C8.37766 0 8.68273 0.29576 8.68273 0.660599V7.68763C8.68273 8.05247 8.37766 8.34823 8.00133 8.34823C7.625 8.34823 7.31992 8.05247 7.31992 7.68763V0.660599Z"
        fill={fill}
      />
      <path
        d="M7.31992 10.3124C7.31992 9.94753 7.625 9.65177 8.00133 9.65177C8.37766 9.65177 8.68273 9.94753 8.68273 10.3124V17.3394C8.68273 17.7042 8.37766 18 8.00133 18C7.625 18 7.31992 17.7042 7.31992 17.3394V10.3124Z"
        fill={fill}
      />
    </svg>
  );
}
