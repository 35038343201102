import React from "react";
import styled from "styled-components";

// --------------------------------------------------------
// 1) Styled Components
// --------------------------------------------------------
const T1 = styled.h1`
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const T2 = styled.h2`
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
`;

const T3 = styled.h3`
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const T4 = styled.h4`
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 8px;
`;

const P = styled.p`
  line-height: 1.6;
  margin-bottom: 12px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
`;

const CodeBlock = styled.pre`
  background-color: #f9f9f9;
  padding: 12px;
  overflow: auto;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 16px;
  border-radius: 4px;
  font-family: "Courier New", Courier, monospace;
`;

const HR = styled.hr`
  border: 0;
  border-top: 1px solid #ccc;
  margin: 20px 0;
`;

const BlockQuoteWrapper = styled.div`
  background-color: #f0f0f0;
  border-left: 4px solid #ccc;
  padding: 10px 15px;
  margin: 15px 0;
  border-radius: 4px;
`;

const BlockQuoteText = styled.blockquote`
  margin: 0;
  font-style: italic;
  font-family: "Raleway", sans-serif;
  color: #555;
`;

const TableWrapper = styled.table`
  border-collapse: collapse;
  margin-bottom: 1em;
  width: 100%;
  font-size: 14px;
  font-family: "Raleway", sans-serif;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  thead {
    background: #f9f9f9;
  }
`;

const UL = styled.ul`
  margin-bottom: 10px;
  padding-left: 25px;
  font-family: "Raleway", sans-serif;
  line-height: 1.6;
`;

const OL = styled.ol`
  margin-bottom: 10px;
  padding-left: 25px;
  font-family: "Raleway", sans-serif;
  line-height: 1.6;
`;

const LI = styled.li`
  margin-bottom: 6px;
  font-size: 14px;
`;

// Optional: If you want to control the styling of links:
const StyledLink = styled.a`
  color: #007acc;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// --------------------------------------------------------
// 2) Inline Transformer & Table Helpers
// --------------------------------------------------------
function transformInline(text) {
  // Bold: **text**
  text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  // Italics: *text* or _text_
  text = text.replace(/\*(.*?)\*/g, "<em>$1</em>");
  text = text.replace(/_(.*?)_/g, "<em>$1</em>");

  // Inline code: `text`
  text = text.replace(/`([^`]+)`/g, "<code>$1</code>");

  // Links: [text](url) => use a custom <StyledLink> if you want
  text = text.replace(
    /\[([^\]]+)\]\(([^)]+)\)/g,
    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
  );

  // Images: ![alt](url)
  text = text.replace(
    /\!\[([^\]]*)\]\(([^)]+)\)/g,
    '<img alt="$1" src="$2" style="max-width:100%;"/>'
  );

  return text;
}

function isTableLine(line) {
  return /^\|.*\|$/.test(line.trim());
}
function isTableSeparator(line) {
  return /^(\|\s*[-:]+\s*)+\|$/.test(line.trim());
}

function renderTable(rows, key) {
  if (!rows.length) return null;
  const headerCells = rows[0].split("|").slice(1, -1).map((h) => h.trim());
  let bodyStartIndex = 1;

  // If the second row is just dashes, skip it
  if (rows[1] && isTableSeparator(rows[1])) {
    bodyStartIndex = 2;
  }

  const headers = (
    <thead>
      <tr>
        {headerCells.map((cell, idx) => (
          <th key={`h-${idx}`}>{cell}</th>
        ))}
      </tr>
    </thead>
  );

  const tableBodyRows = rows.slice(bodyStartIndex).map((row, rowIndex) => {
    const cells = row.split("|").slice(1, -1).map((c) => c.trim());
    return (
      <tr key={`r-${rowIndex}`}>
        {cells.map((cell, cellIndex) => (
          <td
            key={`r-${rowIndex}-c-${cellIndex}`}
            dangerouslySetInnerHTML={{ __html: transformInline(cell) }}
          />
        ))}
      </tr>
    );
  });

  return (
    <TableWrapper key={`table-${key}`}>
      {headers}
      <tbody>{tableBodyRows}</tbody>
    </TableWrapper>
  );
}

// --------------------------------------------------------
// 3) Nested Lists: Detection & Recursive Parsing
// --------------------------------------------------------
function isOrderedListItem(line) {
  return /^(\s*)(\d+)\.\s+(.*)$/.test(line);
}

function isUnorderedListItem(line) {
  return /^(\s*)-\s+(.*)$/.test(line);
}

function getIndentLevel(line) {
  const match = line.match(/^(\s*)/);
  if (!match) return 0;
  const spaces = match[1].length;
  return Math.floor(spaces / 2);
}

function extractListItemInfo(line) {
  let match = line.match(/^(\s*)(\d+)\.\s+(.*)$/);
  if (match) {
    const number = parseInt(match[2], 10);
    const text = match[3];
    return { isOrdered: true, number, text };
  }

  match = line.match(/^(\s*)-\s+(.*)$/);
  if (match) {
    const text = match[2];
    return { isOrdered: false, number: null, text };
  }

  return null;
}

function parseListRecursively(lines, startIndex, parentIndent, parentIsOrdered) {
  let items = [];
  let i = startIndex;
  let startNumber = null;

  while (i < lines.length) {
    const line = lines[i];
    if (!line.trim()) break;

    if (!isOrderedListItem(line) && !isUnorderedListItem(line)) {
      break;
    }

    const indent = getIndentLevel(line);
    if (indent < parentIndent) {
      break;
    } else if (indent > parentIndent) {
      if (items.length > 0) {
        const lastItem = items[items.length - 1];
        const { listElement, nextIndex } = parseListRecursively(
          lines,
          i,
          indent,
          lastItem.isOrdered
        );
        lastItem.subList = listElement;
        i = nextIndex;
        continue;
      } else {
        break;
      }
    } else {
      const info = extractListItemInfo(line);
      if (!info) break;

      if (startNumber === null && info.isOrdered) {
        startNumber = info.number;
      }

      items.push({
        isOrdered: info.isOrdered,
        number: info.number,
        text: info.text,
        subList: null,
      });
      i++;
    }
  }

  let listTag = parentIsOrdered ? OL : UL;
  const listProps = {};

  if (parentIsOrdered && startNumber !== null) {
    listProps.start = startNumber;
  }

  const listElement = React.createElement(
    listTag,
    { ...listProps, key: `nested-list-${startIndex}` },
    items.map((item, idx) => {
      const liChildren = [];
      liChildren.push(
        <span
          key={`li-text-${idx}`}
          dangerouslySetInnerHTML={{ __html: transformInline(item.text) }}
        />
      );
      if (item.subList) {
        liChildren.push(item.subList);
      }

      return <LI key={`li-${idx}`}>{liChildren}</LI>;
    })
  );

  return { listElement, nextIndex: i };
}

function parseListEntry(lines, startIndex) {
  const line = lines[startIndex];
  if (!line) return null;
  if (!isOrderedListItem(line) && !isUnorderedListItem(line)) return null;

  const info = extractListItemInfo(line);
  if (!info) return null;

  const indent = getIndentLevel(line);

  const { listElement, nextIndex } = parseListRecursively(
    lines,
    startIndex,
    indent,
    info.isOrdered
  );

  return { element: listElement, newIndex: nextIndex };
}

// --------------------------------------------------------
// 4) Main Parsing Function
// --------------------------------------------------------
export function formatElements(rawString) {
  if (!rawString) return null;

  // NEW: Unwrap a fenced markdown block if the entire content is wrapped in triple backticks
  const trimmed = rawString.trim();
  if (trimmed.startsWith("```")) {
    const lines = trimmed.split("\n");
    const fenceLine = lines[0].trim();
    // Match ``` or ```markdown or ```md (you can extend this regex as needed)
    if (/^```(markdown|md)?$/.test(fenceLine) && lines[lines.length - 1].trim() === "```") {
      rawString = lines.slice(1, lines.length - 1).join("\n");
    }
  }

  const lines = rawString.split("\n");
  let elements = [];

  let i = 0;
  let inCodeBlock = false;
  let codeBlockContent = [];

  let inTable = false;
  let tableRows = [];

  // For multi-line blockquotes, we'll gather them and transform them once we reach a non-quote line
  let blockQuoteBuffer = [];

  function flushBlockQuotes() {
    if (blockQuoteBuffer.length > 0) {
      // Join all lines with a space or line break
      const combined = blockQuoteBuffer
        .map((l) => l.replace(/^>\s?/, ""))
        .join("\n");
      elements.push(
        <BlockQuoteWrapper key={`bq-${i}`}>
          <BlockQuoteText
            dangerouslySetInnerHTML={{ __html: transformInline(combined) }}
          />
        </BlockQuoteWrapper>
      );
      blockQuoteBuffer = [];
    }
  }

  while (i < lines.length) {
    let line = lines[i] || "";

    // 1) Check for code block delimiters
    if (line.trim().startsWith("```")) {
      flushBlockQuotes(); // flush any open blockquotes before code
      if (!inCodeBlock) {
        inCodeBlock = true;
        i++;
        continue;
      } else {
        // Closing ```
        inCodeBlock = false;
        elements.push(
          <CodeBlock key={`code-${i}`}>{codeBlockContent.join("\n")}</CodeBlock>
        );
        codeBlockContent = [];
        i++;
        continue;
      }
    }

    // If we're inside a code block, just accumulate lines
    if (inCodeBlock) {
      codeBlockContent.push(line);
      i++;
      continue;
    }

    // 2) Tables
    if (isTableLine(line)) {
      flushBlockQuotes(); // flush any open blockquotes before table
      if (!inTable) {
        inTable = true;
        tableRows = [];
      }
      tableRows.push(line);
      i++;
      continue;
    } else if (inTable) {
      // If we were in a table, and have a non-table line => close and render it
      inTable = false;
      elements.push(renderTable(tableRows, i));
      tableRows = [];
      // Do not increment i yet; let other checks handle this line
      continue;
    }

    // 3) HR (horizontal rule)
    if (line.trim().match(/^(-{3,}|\*{3,})$/)) {
      flushBlockQuotes();
      elements.push(<HR key={`hr-${i}`} />);
      i++;
      continue;
    }

    // 4) Blockquotes
    if (line.trim().startsWith(">")) {
      // Accumulate all lines that start with ">" into blockQuoteBuffer
      blockQuoteBuffer.push(line);
      i++;
      continue;
    } else {
      // If there's anything in blockQuoteBuffer and we've hit a non-quote line, flush it
      if (blockQuoteBuffer.length > 0) {
        flushBlockQuotes();
      }
    }

    // 5) Headings
    if (line.startsWith("#### ")) {
      const text = line.replace(/^####\s*/, "").replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T4 key={`t4-${i}`}>{text.trim()}</T4>);
      i++;
      continue;
    } else if (line.startsWith("### ")) {
      const text = line.replace(/^###\s*/, "").replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T3 key={`t3-${i}`}>{text.trim()}</T3>);
      i++;
      continue;
    } else if (line.startsWith("## ")) {
      const text = line.replace(/^##\s*/, "").replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T2 key={`t2-${i}`}>{text.trim()}</T2>);
      i++;
      continue;
    } else if (line.startsWith("# ")) {
      const text = line.replace(/^#\s*/, "").replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T1 key={`t1-${i}`}>{text.trim()}</T1>);
      i++;
      continue;
    }

    // 6) Nested List Handling
    const listCheck = parseListEntry(lines, i);
    if (listCheck) {
      flushBlockQuotes();
      elements.push(listCheck.element);
      i = listCheck.newIndex;
      continue;
    }

    // 7) Fallback: paragraph
    if (line.trim().length > 0) {
      flushBlockQuotes();
      elements.push(
        <P
          key={`p-${i}`}
          dangerouslySetInnerHTML={{ __html: transformInline(line) }}
        />
      );
    }

    i++;
  }

  // If we ended while still in a table => close it
  if (inTable && tableRows.length) {
    elements.push(renderTable(tableRows, i));
  }

  // If there's an unflushed blockquote
  flushBlockQuotes();

  return elements;
}
