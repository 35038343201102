import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { CopilotContext, CopilotProvider } from '../CopilotContext';

import ChangeIntro from '../Functions/Change';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ChangeCard from '../Components/ChangeCard';
import ChangeReport from '../Components/change';
import { SET_NEW_FILTERS } from "constants/actions";
import Empty from '../Components/Empty';


const ChangeHome = ({ goHome, initialSession, onSaveSession }) =>{
      const dispatch = useDispatch();
      const [selectedFactors, setSelectedFactors] = useState({ type: 'Culture' });
      const [started, setStarted] = useState(false);
      const [data, setData] = useState({});
      const [done, setDone] = useState(false);
      
      const selectedFilters = useSelector((state) => state.audit?.new_filters, shallowEqual);

       useEffect(() => {
          return () => {
            dispatch({ type: SET_NEW_FILTERS, payload: [] });
          };
        }, [dispatch]);

      useEffect(() => {
          if (initialSession) {
            // restore messages
            if (initialSession.data) {
                setData(initialSession.data);
                setStarted(true);
                setDone(true);
            }
            if (initialSession.filters) {
              dispatch({ type: SET_NEW_FILTERS, payload: initialSession.filters });
            }
          }
        }, [initialSession, dispatch]);

      useEffect(() => {

        if(done && Object.keys(data).length > 0 && !initialSession){
            onSaveSession({
                type: 'change',
                selectedFactors,
                data,
                filters:selectedFilters
            })
        }
      },[done])


      if(!started){
            return (
                <ChangeIntro 
                selectedFactors={selectedFactors}
                onClick={()=>setStarted(true)}
                setSelectedFactors={setSelectedFactors}/>
            )
      }


    if(done && Object.keys(data).length === 0){
        return <Empty/>
    }

    return (

      <Container>
      <Title>Change Analysis Workflow</Title>

      <Content>
        <MessageContainer>
          <ChangeCard start={()=>setStarted(true)} setData={setData} setDone={setDone} done={done}/>
        </MessageContainer>
      </Content>

      <ChangeReport change_data={data} done={done}/>
    </Container>
    )
}



export default function Change(props) {
  return (
    <CopilotProvider>
      <ChangeHome {...props} />
    </CopilotProvider>
  );
}



const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to   { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  font-family: 'Raleway', sans-serif;
  padding: 20px;
  padding-top: 0px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #2A3039;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

const MessageContainer = styled.div`
  width: 100%;
  max-width: 900px;`;